import _ from 'lodash-es'
import { SectionController } from '../SectionController'
import { DocumentUploadConfig, MultiDocumentUploader } from '../../../controls/documentsUpload/DocumentUploadConfig';
import { ReferenceType } from '../../../models/reference/ReferenceType';
import { ItemGroupId, NoteItemId } from '../../../models/ItemGroup';


enum ReferenceStatusToReview { ReadyForReview = 4, ReferenceReviewed = 6, AwaitingPaperUpload = 7, Approve = 8, UnApprove = 9 }
enum ReferenceStatus { Requested = 1, Resent = 2, StartedByReferee = 3, ReadyForReview = 4, ReadyForReviewWithIssues = 5, Reviewed = 6, AwaitingPaperUpload = 7, Approved = 8}

class ReferenceController extends SectionController {

    public ReferenceType = ReferenceType

    // lists of actual existing references
    childcareReferences: any;
    characterReferences: any;
    educareReferences: any;
    familyReferences: any;
    familyMemberReferences: any;
    documentUploadConf: DocumentUploadConfig<MultiDocumentUploader>;
    childcareExperience: any;
    currentSelectedReference: any;
    anyPreviousHostFamilyChildcareAdded: any;
    anyCaringForYoungerFamilyMemberAdded: any;
    isOfficer: any;
    isAgent: any;
    isInterviewer: any;
    isBranchManager: any;
    isApplicant: any;
    referenceReview: any;

    static override $inject = ['$rootScope', '$state', 'Auth', 'ReevaluateSection', '$scope', '$q', '$uibModal', 'dialogs', 'Reference', 'references', 'referenceSummary', 'childcareExperience', 'program', 'applicationId', 'childcareId', 'review', 'ApplicationUploader', 'applicationObservable'];
    constructor($rootScope, private $state, Auth, ReevaluateSection, $scope, private $q: ng.IQService, private $uibModal, dialogs, private Reference, public references, public referenceSummary, childcareExperience, public program, applicationId, childcareId, review, private ApplicationUploader, applicationObservable) {
        super($rootScope, $scope, Auth, ReevaluateSection, review, applicationId, ItemGroupId.References, applicationObservable, dialogs);

        this.referenceReview = review;

        this.isOfficer = Auth.user.isOfficer;
        this.isAgent = Auth.user.isAgent;
        this.isInterviewer = Auth.user.isInterviewer;
        this.isBranchManager = Auth.user.isBranchManager;
        this.isApplicant = Auth.user.isApplicant;
        this.childcareReferences = _.filter(referenceSummary.references, { referenceType: ReferenceType.Childcare });
        this.characterReferences = _.filter(referenceSummary.references, { referenceType: ReferenceType.Character });
        this.educareReferences = _.filter(referenceSummary.references, { referenceType: ReferenceType.Educare });
        this.familyReferences = _.filter(referenceSummary.references, { referenceType: ReferenceType.PreviousHostFamily });
        this.familyMemberReferences = _.filter(referenceSummary.references, { referenceType: ReferenceType.FamilyMember });

        this.anyPreviousHostFamilyChildcareAdded = childcareExperience.displayHostFamilyReferenceSection;

        this.anyCaringForYoungerFamilyMemberAdded = childcareExperience.displayFamilyMemberReferenceSection;


        this.childcareExperience = childcareExperience.childcareExperience;
        _.remove(this.childcareExperience, (obj:any) => <boolean>_.find(this.childcareReferences, { referenceTargetId: obj.id }));            
    }

    frequencyDescription(careFrequency) {
        switch (careFrequency) {
            case 'D':
                return 'Daily';
            case 'M':
                return 'Monthly';
            case 'W':
                return 'Weekly';
            default:
                return '';
        }
    }

    reviewRef(entry) {
        switch (entry.status) {
            case ReferenceStatusToReview.ReadyForReview:
            case ReferenceStatusToReview.ReferenceReviewed:
            case ReferenceStatusToReview.Approve:
            case ReferenceStatusToReview.UnApprove:
                this.$state.go('.inst', { referenceId: entry.id });
                break;
            default:
                //do nothing;
        }
    }

    editRef(entry) {
            
            this.$uibModal.open({
            templateUrl: 'areas/application/reference/modals/referenceModalEdit.html',
            controller: 'ReferenceModalController as refCtrl',
            backdrop: false,
            resolve: {
                title: () => 'Edit reference request',
                reference: () => {
                    var result = angular.copy(entry);
                    result.oldReferenceTargetId = entry.referenceTargetId;
                    result.referenceType = entry.referenceType;
                    return result;
                }, 
                canEditRef: () => this.canEditReference(entry),
                review: () => this.referenceReview
            }
        }).result.then((data) => {
            this.replaceEntry(entry, data)
        });
    }

    uploadDocuments(entry) {
        var referenceCategoryTypeId;

        //3: Childcare, 1: Character, 4: Previous Host Family, 2: Educare, 5: family member
        if (entry.referenceType == 3) {
            referenceCategoryTypeId = 13;
        }
        else if (entry.referenceType == 1) {
            referenceCategoryTypeId = 14;
        }
        else if (entry.referenceType == 4) {
            referenceCategoryTypeId = 15;
        }
        else if (entry.referenceType == 2) {
            referenceCategoryTypeId = 16;
        }
        else if (entry.referenceType == 5) {
            referenceCategoryTypeId = 24;
        }

        let uploader = new this.ApplicationUploader(entry.applicationId, referenceCategoryTypeId, 0, entry.id);

        this.documentUploadConf = {
            uploader: uploader,
            onUpdateHandler: () => {            
                this.Reference.updateReferenceStatus({ id: entry.id, status: 4 }).$promise.then((data) => {
                    this.replaceEntry(entry, data);
                });
            },
            onDeleteHandler: () => {
                this.Reference.updateReferenceStatus({ id: entry.id, status: 7 }).$promise.then((data) => {                       
                    this.replaceEntry(entry, data);
                });
            }
        }
        this.currentSelectedReference = entry;
    }

    downloadReference(id) {
        this.Reference.referenceToken({ userId: id }).$promise.then((response) => {
            var url = this.$state.href('app.reference.overrideViews.referee', { token: response.token });
            window.open(url, '_blank');
        });
    }

    private add(reference) {
        reference.applicationId = this.applicationId;
        reference.review = this.referenceReview;
        return this.$uibModal.open({
            templateUrl: 'areas/application/reference/modals/referenceModalAdd.html',
            controller: 'ReferenceModalController as refCtrl',
            backdrop: false,
            resolve: {
                title: () => 'Add reference request',
                reference: () => reference,
                canEditRef: () => true
            }
        }).result;
    }

    addCharacter() {
        this.add({
            referenceType: ReferenceType.Character
        }).then((data) => {
            this.characterReferences.push(data);
        });
    }

    addEducare() {
        this.add({
            referenceType: ReferenceType.Educare
        }).then((data) => {
            this.educareReferences.push(data);
        });
    }

    addChildcare() {

        this.add({
            referenceType: ReferenceType.Childcare,
            applicationId: this.applicationId,
        }).then((data) => {
            this.childcareReferences.push(data);
        });
    }

    addPreviousHostFamily() {
        this.add({
            referenceType: ReferenceType.PreviousHostFamily
        }).then((data) => {
            this.familyReferences.push(data);
        });
    }


    addFamilyMemberRef() {
        this.add({
            referenceType: ReferenceType.FamilyMember
        }).then((data) => {
            this.familyMemberReferences.push(data);
        });
    }

    private getCollectionForReference(referenceType: ReferenceType) {
        switch (referenceType) {
            case ReferenceType.Childcare:
                return this.childcareReferences;
            case ReferenceType.Character:
                return this.characterReferences;
            case ReferenceType.Educare:
                return this.educareReferences;
            case ReferenceType.PreviousHostFamily:
                return this.familyReferences;
            case ReferenceType.FamilyMember:
                return this.familyMemberReferences;
            default:
                return [];
        }
    }

    callFunction(reference, event, typeOfRequest) {
        switch (typeOfRequest) {
            case 'edit':
                this.editRef(reference);
                break;
            case 'remove':
                this.remove(reference, event);
                break;
            case 'print':
                this.openURL(reference)
                break;
            case 'upload':
                this.uploadDocuments(reference);
                break;
        }

        if (event) {
            event.stopPropagation();
        }
    }

    openURL(url) {
        window.open(url, '_blank');
    }

    remove(entry, event) {
        this.dialogs.confirm('Confirm Delete', 'Are you sure that you want to delete this reference?')
            .result
            .then(() => {
                this.Reference
                    .remove({}, { id: entry.id })
                    .$promise
                    .then(() => {
                        this.$scope.$root.$broadcast('note:removeNoteByAssignId', { groupId: ItemGroupId.References, itemGroupId: NoteItemId.References, assignId: entry.id });
                        this.$scope.$root.$broadcast('push:uploadChange', this.applicationId);

                        var collection = this.getCollectionForReference(entry.referenceType);
                        var i = collection.indexOf(entry);
                        if (i > -1) {
                            collection.splice(i, 1);
                        }

                    }, () => {
                        this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If the problem persists, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>');
                    });
            });

    }

    resendRequest(entry) {
        if (!this.canResendReference(entry)) {
            return
        }

        this.dialogs
            .confirm('Resend Reference Request', 'Are you sure that you wish to resend this request for a reference?')
            .result.then((data) => {
                this.Reference.resendReferenceRequest({}, { id: entry.id })
                    .$promise
                    .then((data) => {
                        this.replaceEntry(entry, data)
                        this.dialogs.notify('Request Resent', 'The reference request has been resent to the requested referee.');
                    }, () => {
                        this.dialogs.error('Error', 'There was a problem resending the reference request, please try again later or If the problem persists, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>');
                    });
            });

    }

    private getReferenceRequirement(referenceType: ReferenceType): any { //TODO:: put proper type instead of any
        return _.find(this.referenceSummary.requirements, { referenceType: referenceType });
    }

    private getRemainingRequiredCount(referenceType: ReferenceType) {
        return this.getReferenceRequirement(referenceType).itemsRequired - this.getCollectionForReference(referenceType);
    }

    public getRemainingRequiredRange(referenceType: ReferenceType) {
        return _.range(0, this.getRemainingRequiredCount(referenceType), 1);
    }

    public canResendReference(reference) {
        var canResentRef = false;

        //ReferenceStatuss
        switch (reference.status) {
            case ReferenceStatus.Requested:
            case ReferenceStatus.StartedByReferee:
                canResentRef = true;
                break;
            default:
                // do nothing
        }

        return canResentRef;

        //return reference.status === 1 || reference.status === 2 || reference.status === 7
    }

    public canEditReference(reference) {
        var canEditRef = false;

        //ReferenceStatuss
        switch (reference.status) {
            case ReferenceStatus.Requested:
            case ReferenceStatus.AwaitingPaperUpload:
            case ReferenceStatus.Resent:
            case ReferenceStatus.StartedByReferee:
            case ReferenceStatus.ReadyForReview:
            case ReferenceStatus.ReadyForReviewWithIssues:
                canEditRef = true;
                break;
            case ReferenceStatus.Reviewed:
                canEditRef = (this.isApplicant && !reference.uploadPaperReferences) ? false : true;
                break;
            case ReferenceStatus.Approved:
                canEditRef = (this.isOfficer) ? true : false;
                break;
            default:
            //do nothing;
        }

        return canEditRef;
    }

    public isManagementUsers() {
        return (this.isOfficer || this.isInterviewer || this.isAgent || this.isBranchManager);
    }

    public disablePaperReferenceUpload(reference) {
        var canUploadDoc = false;

        //ReferenceStatuss
        switch (reference.status) {
            case ReferenceStatus.AwaitingPaperUpload:
            case ReferenceStatus.ReadyForReview:
            case ReferenceStatus.ReadyForReviewWithIssues:
            case ReferenceStatus.Reviewed:
                canUploadDoc = true;
                break;
            case ReferenceStatus.Approved:
                canUploadDoc = (this.isOfficer) ? true : false;
                break;
            default:
            //do nothing;
        }

        return !canUploadDoc;

        //return reference.status === 6 || reference.status === 8
    }       

    private replaceEntry(entry, data) {
        var collection = this.getCollectionForReference(entry.referenceType)
        var i = collection.indexOf(entry)
        if (i > -1) collection[i] = data
    }
}

angular
    .module('app')
    .controller('ReferenceController', ReferenceController);
