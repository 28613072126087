import { SectionController } from '../SectionController';
import { ReviewPage } from '../../../services/ReviewService';
import { ItemGroupId } from '../../../models/ItemGroup';
import { ReviewItemId } from '../../../models/ReviewItem';

class HighlightsController extends SectionController {

    aboutYou: any;
    media: any;
    hostFamilyLetter: any;
    lastAutoSave: Date;
    displayApproveButton: boolean;
    isOfficeUser: boolean;
    isApplicant: boolean;
    page: ReviewPage;
    applicationStatus: string;


    static override $inject = ['$rootScope', '$scope', '$q', 'dialogs', 'AboutYou', 'Hobby', 'review', 'profilePage', 'applicationId', 'applicantHobbies', 'ApproveChanges', 'Auth', 'ReevaluateSection', 'applicationObservable'];
    constructor(
        $rootScope: angular.IRootScopeService,
        $scope: angular.IScope,
        private $q: angular.IQService,
        dialogs,
        private AboutYou,
        private Hobby,
        review,
        profilePage,
        applicationId,
        public applicantHobbies,
        private ApproveChanges,
        Auth,
        ReevaluateSection,
        applicationObservable
    ) {
        super($rootScope, $scope, Auth, ReevaluateSection, review, applicationId, ItemGroupId.Profile, applicationObservable, dialogs);

        this.isOfficeUser = Auth.user.isOfficer;
        this.isApplicant = Auth.user.isApplicant;

        if (this.applicantHobbies) {
            this.applicantHobbies.applicationId = applicationId;
        }

        this.page = review.getPage(ItemGroupId.Profile);
        this.displayApproveButton = this.page && this.page.approveChange && this.isOfficeUser;

        this.aboutYou = profilePage.aboutYou
        this.media = profilePage.media

        $scope.$watch(() => this.$scope.highlightsForm.$dirty, () => {
            this.$dirty = this.$scope.highlightsForm.$dirty
        })

        applicationObservable.subscribe(i => {
            this.applicationStatus = i.status;
        })

        $scope.$on('profile:refresh', (nam, type, data) => {
            this.refreshSection(type, data);
        })
    }

    refreshSection(type, data) {
        switch (type) {
            case ReviewItemId.AboutYou:
                this.aboutYou = data;
                break;
            case ReviewItemId.Hobbies:
                this.applicantHobbies = data;
                break;
            default:
                break;
        }
    }


    save() {
        this.$scope.$broadcast('submitted', true)

        var promisses = []
        var form = this.$scope.highlightsForm

        if (form['SkillsAndInterests'].$dirty) {
            this.applicantHobbies.metaform = form['SkillsAndInterests'].$metaformVersion
            promisses.push(
                this.Hobby
                    .update(this.applicantHobbies)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.Hobbies)))
        }

        if (form['AboutYou'].$dirty) {
            this.aboutYou.metaform = form['AboutYou'].$metaformVersion
            promisses.push(
                this.AboutYou
                    .update(this.aboutYou)
                    .$promise
                    .then((data) => {
                        if (data) {
                            this.aboutYou.comfHouseholdTypes = data.comfHouseholdTypes;
                        }

                        this.sectionChanged(ReviewItemId.AboutYou)
                    }))
        }

        //if (form['HostFamilyLetter'].$dirty) {
        //    promisses.push(
        //        this.HostFamilyLetter.update(this.hostFamilyLetter).$promise)
        //}

        this.$q.all(promisses)
            .then((ss) => {
                form.$setPristine();
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })
            }, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            })
    }

    changesApproved() {

        var user = this.Auth.user
        var data = {
            applicationId: this.applicationId,
            item: ItemGroupId.Profile,
            author: user.firstName + ' ' + user.lastName
        };

        this.ApproveChanges
            .update({ applicationId: this.applicationId }, data)
            .$promise
            .then(data => {
                if (this.displayApproveButton === true) {
                    this.displayApproveButton = false;
                    this.page.approveChange = false;
                }

                this.alerts.push({
                    type: 'success',
                    msg: 'Approval complete',
                    dismissOnTimeout: 5000
                });
            }, () => {
                this.alerts.push({
                    type: 'danger',
                    msg: 'We are sorry but this form could not be approved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            });
    }

}

angular
    .module('app')
    .controller('HighlightsController', HighlightsController)
