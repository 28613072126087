 

class ApplicationReviewDirective implements ng.IDirective {
    restrict = 'AE'
    controller = ApplicationReviewController
    controllerAs = '$ctrl'
    bindToController = true
    templateUrl = 'controls/ApplicationReview/applicationReview.html'
    scope = {
        applicationId: '=',
        init: '=?',
        initUsers: '=?'
    }
}

class ApplicationReviewController {
    applicationId: number
    init: boolean;
    initUsers;

    reviewingByMe: boolean;
    reviewingByOther: boolean;
    reviewerTitle: string;
    users;

    static $inject = ['$scope', 'PushHub', 'Auth'];
    constructor(private $scope, private PushHub, private Auth) {
            
        $scope.$on('push:applicationObserversChange', (event, message) => {
            if (this.applicationId === message.applicationId) {

                this.update(message.users);
            }
        });
    }

    $onInit() {
        if (this.init) {
            this.update(this.initUsers || []);
        } else {
            this.PushHub.getApplicationObservers().then((observers) => {
                this.update(observers || []);
            });
        }
    }

    private update(users) {
        this.users = users;

        this.Auth.user.userId

        if (users.length == 0) {
            this.reviewingByMe = false;
            this.reviewingByOther = false;
            this.reviewerTitle = '';

        } else if (users.length == 1) {
            if (this.Auth.user.userId == users[0].userId) {
                this.reviewingByMe = true;
                this.reviewingByOther = false;
            } else {
                this.reviewingByMe = false;
                this.reviewingByOther = true;
                this.reviewerTitle = users[0].name + ' is reviewing';
            }
        } else {
            this.reviewingByMe = false;
            this.reviewingByOther = true;
            this.reviewerTitle = users.join(', ') + ' are reviewing';
        } 
    }
}


angular
    .module('app')
    .directive('applicationReview', () => new ApplicationReviewDirective());