
export class InterestedFamiliesController {

    interestedFamilies: any[];
    isOnlineOrAwaiting: boolean;
    isToBeMatch: boolean;
    isRematch: boolean;
    isActiveApp: boolean;
    isPlacedOrActive: boolean;
    isPlaced: boolean;
    interestedFamiliesRequestStatus: string;
    pndMatch: boolean;
    matchingVideo;

    static $inject = ['$scope', '$sce', '$uibModal', 'dialogs', 'applicationId', 'PlacementMatchesDetails', 'InterestedHostFamiliesDetails', 'applicationObservable', '$state', '$stateParams', 'Auth']
    constructor($scope: angular.IScope, $sce, private $uibModal, private dialogs, public applicationId, public PlacementMatchesDetails, public InterestedHostFamiliesDetails, applicationObservable, private $state, private $stateParams, private Auth) {

        this.matchingVideo = [
            { src: $sce.trustAsResourceUrl("https://aupairamericalive.s3.amazonaws.com/resources/I/Matching%20Video.mp4"), type: 'video/mp4' }
        ],

        applicationObservable.subscribe(applicationInstance => {
            this.isToBeMatch = (applicationInstance.status === 'TBM');
            this.isRematch = (applicationInstance.status === 'REM');
            this.isActiveApp = (applicationInstance.status === 'ACT');
            this.isPlaced = (applicationInstance.status === 'PLC');
            this.isOnlineOrAwaiting = (applicationInstance.status === 'OLA' || applicationInstance.status === 'AWA');
            this.isPlacedOrActive = (applicationInstance.status === 'PLC' || applicationInstance.status === 'ACT');

            if (this.isOnlineOrAwaiting || this.isToBeMatch) {
                PlacementMatchesDetails.getMatches({ applicationId: applicationId, status: 'PND' }).$promise.then((matches) => {
                    this.pndMatch = matches && matches.length > 0;
                });
            }

            if (this.isPlacedOrActive || this.isToBeMatch || this.isRematch) {
                this.loadIntrestedFamilies();
            }  
        })  
    }

    loadIntrestedFamilies() {
        this.interestedFamiliesRequestStatus = 'loading';
        this.interestedFamilies = undefined;

        this.InterestedHostFamiliesDetails.query({
            applicationId: this.applicationId
        })
            .$promise
            .then((result) => {
                this.interestedFamiliesRequestStatus = 'completed';
                this.interestedFamilies = result;
            }, () => {
                this.interestedFamiliesRequestStatus = 'failed';
            });
    }

    selectedProposal(hfData){

        this.$stateParams.proposalId = hfData.proposalId;
        this.$stateParams.photosUrl = hfData.photosURL;
        this.$stateParams.essayUrl = hfData.essayURL;
        this.$stateParams.email = hfData.email;

        var param = {
            proposalId: hfData.proposalId,
            photosUrl: hfData.photosURL,
            essayUrl: hfData.essayURL,
            email: hfData.email,
            requestId: hfData.requestId,
            hostFamilyId: hfData.hostFamilyId
        }
        if (this.Auth.user.isApplicant) {
            this.$state.go('app.hostFamilies.hostFamilyDocuments', param);
        } else {
            this.$state.go('app.applications.hostFamilies.hostFamilyDocuments', param);
        }

    }

    interested(hf) {
        this.openFeedbackDialog(hf, true);
    }

    notInterested(hf) {
        this.openFeedbackDialog(hf, false);
    }

    openFeedbackDialog(hf, intrested: boolean) {

        if (hf.proposalId != null || hf.essayURL != null || hf.email != null || hf.photosURL != null) {
            var template = intrested
                ? 'areas/hostFamilies/hostFamilyFeedback/hostFamilyPositiveFeedbackModal.html'
                : 'areas/hostFamilies/hostFamilyFeedback/hostFamilyNegativeFeedbackModal.html';

            this.$uibModal.open({
                templateUrl: template,
                controller: 'HostFamilyFeedbackModalController as $ctrl',
                backdrop: false,
                size: 'lg',
                resolve: {
                    proposalId: () => hf.proposalId,
                    interested: () => intrested,
                    requestId: () => hf.requestId,
                    hostFamilyId: () => hf.hostFamilyId,
                    applicationId: () => this.applicationId,
                    familyFullName: () => hf.familyFullName
                },
                params: {
                    proposalId: hf.proposalId,
                    interested: null,
                    reasonToDeclineCode: null,
                    requestId: hf.requestId,
                    hostFamilyId: hf.hostFamilyId,
                    applicationId: this.applicationId,
                    familyFullName: hf.familyFullName

                }
            }).result.then(() => {
                this.loadIntrestedFamilies();
            });
        }
        else {
            this.dialogs.error('Error', 'Please choose a family.')
        }
    }
}

angular
    .module('app')
    .controller('InterestedFamiliesController', InterestedFamiliesController)
