
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
import { RoutingProvider, state } from '../../services/RoutingService';
import { AccessLevelView } from '../accessLevel';

import { PlacementDetailsController } from '../../areas/placement/placementDetails/placementDetailsController';

class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {


        const placement: state<AccessLevelView> = {
            name: 'placement',
            url: '/placement',
            abstract: true,
            views: {
                content: {
                    applicant: {
                        controller: 'PlacementController as ctrl',
                        template: 'areas/placement/applicant.html'
                    }
                }
            },
            resolve: {
                //applicationId: ['$stateParams', 'Auth', function ($stateParams, Auth) {
                //    if ($stateParams.applicationId) {
                //        return $stateParams.applicationId;
                //    } else if (Auth.user && Auth.user.roleDetails && Auth.user.roleDetails.applicationId) {
                //        return Auth.user.roleDetails.applicationId;
                //    }
                //}],
                //applicationInstanceManager: ['ApplicationInstance', 'applicationId', function (ApplicationInstance, applicationId) {
                //    if (applicationId) {
                //        return ApplicationInstance(applicationId);
                //    }
                //}],
                //applicationInstance: ['applicationInstanceManager', function (applicationInstanceManager) {
                //    if (applicationInstanceManager) {
                //        return applicationInstanceManager.instance.$promise;
                //    }
                //}],
                $title: () => 'Placement'
            },
            states: [
                {
                    name: 'placementDetails',
                    url: '/',
                    views: {
                        content: {
                            controllerAs: '$ctrl',
                            loggedin: {
                                controller: PlacementDetailsController,
                                template: 'areas/placement/placementDetails/placementDetails.html'
                            }
                        }
                    }
                },
                {
                    name: 'flightDetails',
                    url: '/flightInformation',
                    views: {
                        content: {
                            loggedin: {
                                controller: 'FlightDetailsController as ctrl',
                                template: 'areas/placement/flightDetails/flightDetails.html'
                            }
                        }
                    },
                    resolve: {
                        user: ['Auth', function (Auth) {
                            return Auth.user
                        }]
                    }
                },
                {
                    name: 'sharedFlightContactDetails',
                    url: '/sharedFlightContactInformation',
                    views: {
                        content: {
                            loggedin: {
                                controller: 'SharedFlightContactDetailsController as ctrl',
                                template: 'areas/placement/flightDetails/sharedFlightContactDetails.html'
                            }
                        }
                    }
                },
                {
                    name: 'visaDetails',
                    url: '/visaInformation',
                    views: {
                        content: {
                            loggedin: {
                                controller: 'VisaDetailsController as ctrl',
                                template: 'areas/placement/visaDetails/visaDetails.html'
                            }
                        }
                    },
                    resolve: {
                        applicationStatusHistory: ['ApplicationStatusHistory', 'applicationId', function (ApplicationStatusHistory, applicationId) {
                            return ApplicationStatusHistory.query({ applicationId: applicationId }).$promise
                        }]
                    }
                },
                {
                    name: 'preDeparture',
                    url: '/preDeparture',
                    views: {
                        content: {
                            loggedin: {
                                controller: 'PreDepartureController as ctrl',
                                template: 'areas/placement/preDeparture/preDeparture.html'
                            }
                        }
                    }
                },
                {
                    name: 'orientation',
                    url: '/orientation',
                    views: {
                        content: {
                            loggedin: {
                                controller: 'OrientationController as ctrl',
                                template: 'areas/placement/orientation/orientationDetails.html'
                            }
                        }
                    }
                },
                {
                    name: 'beforeYouDepart',
                    url: '/beforeYouDepart',
                    views: {
                        content: {
                            loggedin: {
                                controller: 'BeforeYouDepartController as ctrl',
                                template: 'areas/placement/beforeYouDepart/beforeYouDepart.html'
                            }
                        }
                    },
                    resolve: {
                        beforeYouDepartStatus: ['ApplicationPlacement', 'applicationId', function (ApplicationPlacement, applicationId) {
                            return ApplicationPlacement.beforeDepartStatus({
                                applicationId: applicationId
                            }).$promise;
                        }]
                    }
                },
                {
                    name: 'placementprocess',
                    url: '/placementprocess',
                    views: {
                        content: {
                            applicant: {
                                template: 'areas/interestedHostFamily/placementprocess.html'
                            }
                        }
                    }
                },
                
            ]
        };

        const placementForStuff: state<AccessLevelView> = {
            name: 'placement',
            url: '/placement',
            abstract: true,
            views: {
                '': {
                    loggedin: {
                        controller: 'PlacementController as ctrl',
                        template: 'areas/placement/office.html'
                    }
                }
            },
            resolve: {
                $title: () => 'Placement'
            },
            states: [
                {
                    name: 'placementDetails',
                    url: '/',
                    views: {
                        content: {
                            controllerAs: '$ctrl',
                            loggedin: {
                                controller: PlacementDetailsController,
                                template: 'areas/placement/placementDetails/placementDetails.html'
                            }
                        }
                    }
                },
                {
                    name: 'flightDetails',
                    url: '/flightInformation',
                    views: {
                        content: {
                            loggedin: {
                                controller: 'FlightDetailsController as ctrl',
                                template: 'areas/placement/flightDetails/flightDetails.html'
                            }
                        }
                    },
                    resolve: {
                        user: ['Auth', function (Auth) {
                            return Auth.user
                        }]
                    }
                },
                {
                    name: 'sharedFlightContactDetails',
                    url: '/sharedFlightContactInformation',
                    views: {
                        content: {
                            loggedin: {
                                controller: 'SharedFlightContactDetailsController as ctrl',
                                template: 'areas/placement/flightDetails/sharedFlightContactDetails.html'
                            }
                        }
                    }
                },
                {
                    name: 'visaDetails',
                    url: '/visaInformation',
                    views: {
                        content: {
                            loggedin: {
                                controller: 'VisaDetailsController as ctrl',
                                template: 'areas/placement/visaDetails/visaDetails.html'
                            }
                        }
                    },
                    resolve: {
                        applicationStatusHistory: ['ApplicationStatusHistory', 'applicationId', function (ApplicationStatusHistory, applicationId) {
                            return ApplicationStatusHistory.query({ applicationId: applicationId }).$promise
                        }]
                    }
                },
                {
                    name: 'editVisaDetails',
                    url: '/edit/VisaInformation',
                    views: {
                        content: {
                            loggedin: {
                                controller: 'VisaDetailsEditController as ctrl',
                                template: 'areas/placement/visaDetails/visaDetailsEdit.html'
                            }
                        }
                    }
                },
                {
                    name: 'preDeparture',
                    url: '/preDeparture',
                    views: {
                        content: {
                            loggedin: {
                                controller: 'PreDepartureController as ctrl',
                                template: 'areas/placement/preDeparture/preDeparture.html'
                            }
                        }
                    }
                },
                {
                    name: 'orientation',
                    url: '/orientation',
                    views: {
                        content: {
                            loggedin: {
                                controller: 'OrientationController as ctrl',
                                template: 'areas/placement/orientation/orientationDetails.html'
                            }
                        }
                    }
                },
                {
                    name: 'beforeYouDepart',
                    url: '/beforeYouDepart',
                    views: {
                        content: {
                            loggedin: {
                                controller: 'BeforeYouDepartController as ctrl',
                                template: 'areas/placement/beforeYouDepart/beforeYouDepart.html'
                            }
                        }
                    },
                    resolve: {
                        beforeYouDepartStatus: ['ApplicationPlacement', 'applicationId', function (ApplicationPlacement, applicationId) {
                            return ApplicationPlacement.beforeDepartStatus({
                                applicationId: applicationId
                            }).$promise;
                        }]
                    }
                },
                {
                    name: 'placementprocess',
                    url: '/placementprocess',
                    views: {
                        content: {
                            applicant: {
                                template: 'areas/interestedHostFamily/placementprocess.html'
                            }
                        }
                    }
                }

            ]
        };

        RoutingProvider.addState(placement, 'app');
        RoutingProvider.addState(placementForStuff, 'app.applications');
    }
}

angular
    .module('app')
    .config(RoutingConfig);