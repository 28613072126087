import { ReviewPage, ReviewService } from '../../../services/ReviewService';
import { DocumentUploadConfig, SingleDocumentUploader } from '../../../controls/documentsUpload/DocumentUploadConfig';
import { UserRole } from '../../home/recentlyViewed/recentlyViewedController';
import { ApplicationPageErrorsController } from '../../../controls/applicationGroupErrors/ApplicationGroupErrorsController';



class ApplicationInstanceController {

    profilePhotoUploadConfig: DocumentUploadConfig<SingleDocumentUploader>
    menu: ReviewPage[];
    isOfficeUser: number;
    isInterviewer: number;
    isBranchManager: number;
    isAgent: number;
    interviewerName: string;
    interviewerId: number;
    applicantEmailAddress: string;
    isOnHold: boolean;
    statusToOnHoldLabel: boolean

    applicationInstance;
    applicationStatus: string;
    canLoginAsInterviewer: boolean;


    static $inject = ['$scope', '$state', 'dialogs', '$rootScope', 'Auth', 'ApplicationStatusHistory', '$uibModal',
        'ApplicationUploader', 'applicationId', 'review', 'ReviewApplication', 'RecentlyViewed',
        'applicationObservable', 'ReviewablePage'];
    constructor(private $scope, private $state, private dialogs, private $rootScope, private Auth,
        private ApplicationStatusHistory, private $uibModal, ApplicationUploader, private applicationId,
        private review: ReviewService, private ReviewApplication, RecentlyViewed,
        applicationObservable, private ReviewablePage) {
        let uploader = new ApplicationUploader(applicationId, 1);
        var usr = Auth.user;

        applicationObservable.refresh();
        applicationObservable.subscribe(applicationInstance => {
            this.applicationInstance = applicationInstance;
            this.statusToOnHoldLabel = applicationInstance.status == 'AWA' || applicationInstance.status == 'TBM' || applicationInstance.status == 'PLC';
            this.interviewerId = applicationInstance.interviewerId;
            this.interviewerName = applicationInstance.interviewerName || 'Not assigned';
            this.applicationStatus = applicationInstance.status;
            this.canLoginAsInterviewer = this.isOfficeUser && applicationInstance.isInterviewerActive;
        })

        if (!usr.isApplicant) {
            RecentlyViewed.save({
                userId: Auth.user.userId,
                applicationId: applicationId
            })
        }
        

        this.profilePhotoUploadConfig = {
            uploader: uploader,
            hideDescription: true,
            msg: {
                upload: 'Add profile picture'
            }
        }

        this.isOfficeUser = usr.isOfficer;
        this.isInterviewer = usr.isInterviewer;
        this.isBranchManager = usr.isBranchManager;
        this.isAgent = usr.isAgent;
        this.updateMenu();
        //ApplicationOnHold.get({
        //    applicationId: this.applicationId
        //}, (result) => {
        //    this.$rootScope.$broadcast('changedOnHoldStatus', { onHold: result.onHold });
        //})

        

        $scope.$on('changeInterviewerAndAgency', (event, data) => {
            if (data.interviewerFirstName && data.interviewerLastName)
                this.interviewerName = `${data.interviewerFirstName} ${data.interviewerLastName}` || 'Not assigned';
            else if (!data.interviewerSelected) {
                this.interviewerName = 'Not assigned';
            }
        });

        $scope.$on('reviewService:pageCompletnessChange', () => {
            this.updateMenu();
            //$scope.$apply();
        });
        $scope.$on('refreshMenu:itemUpdated', (event, page: ReviewPage) => {
            var item: ReviewPage = this.menu.filter(p => p.group === page.group)[0];
            item.isComplete = page.isComplete;
            item.hasChanges = page.hasChanges;
            item.hasNote = page.hasNote;
            item.isUploadComplete = page.isUploadComplete;
            item.approveChange = page.approveChange;

            $scope.$apply();
        });

        $scope.$on('reviewService:pageNotesChange', () => {
            this.updateMenu();
            //$scope.$apply();
        });

        $scope.$on('changedOnHoldStatus', (event, onHoldStatus) => {
            this.isOnHold = onHoldStatus.onHold;
        });
    }

    updateMenu() {

        this.menu = this.review.pages.filter(page => page.menuItem.applicantOrderNum > 0).sort(page => - page.menuItem.applicantOrderNum)
    }

    toggleCompleteSection(page, event) {
        var data = {
            applicationId: this.applicationId,
            itemGroupId: page.group,
            isItemGroupComplete: !page.isItemGroupComplete,
            reviewedByUserId: this.Auth.user.userId
        };

        this.ReviewApplication.completeSection({ applicationId: this.applicationId }, data)
            .$promise
            .then(() => {
                page.isItemGroupComplete = !page.isItemGroupComplete;
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while complete section application. Please try again after a while.')
            });

    }

    signinAsUser(roleType, relatedId) {
        var usr = this.Auth.user;
        if (usr.isOfficer) {
            this.dialogs.confirm('Please Confirm', 'Are you sure that you wish to log out and log in as other user?')
                .result
                .then(() => {
                    this.$scope.$broadcast('submitted', true);
                    this.Auth.loginAsUser(roleType, relatedId)
                        .then(() => {
                            this.$state.transitionTo('app.home', {}, { reload: true, inherit: true, notify: true });
                        }, (error) => {
                            this.dialogs[error.type](error.title, error.message);
                        });
                });
        }
    }

    signinAsApplicant() {
        return this.signinAsUser('APP', this.applicationId);
    }

    signinAsInterviewer() {
        if (this.canLoginAsInterviewer) {
            return this.signinAsUser('INT', this.interviewerId);
        }
    }

    viewStateChangeHistory() {
        this.$uibModal.open({
            templateUrl: 'areas/application/viewStatusHistory/viewStatusHistory.html',
            controller: ViewStateChangeModalController,
            controllerAs: 'schCtrl',
            backdrop: false,
            resolve: {
                statusChange: () => this.ApplicationStatusHistory.query({
                    applicationId: this.applicationId
                })
            }
        }).result;
    }

    showErrorDetails(page) {
        return this.$uibModal.open({
            templateUrl: 'controls/applicationGroupErrors/applicationGroupErrors.html',
            controller: ApplicationPageErrorsController,
            controllerAs: '$ctrl',
            backdrop: false,
            resolve: {
                name: () => page.menuItem.name,
                invalidSections: () => this.ReviewablePage.applicationGroupErrors({
                    applicationId: this.applicationId,
                    groupId: page.group
                })
            }
        }).result;
    }
}

class ViewStateChangeModalController {

    static $inject = ['$uibModalInstance', 'statusChange'];
    constructor(private $uibModalInstance, public statusChange) { }

    close() {
        this.$uibModalInstance.dismiss();
    }
}

angular
    .module('app')
    .controller('ApplicationInstanceController', ApplicationInstanceController);