
export class HostFamilyDocumentController {

    familyEssayUrl: any;
    familyPhotosUrl: any;
    familyEmail: any;
    familyProposalId: any;
    familyId: any;
    familyRequestId: any;
    interestedHFamilyData: any;
    essayHtmlPage: any;
    photosHtmlPage: any;
        
    static $inject = ['$scope', 'applicationId', '$state', 'InterestedHostFamiliesDetails', '$stateParams', 'proposalId', 'email', 'essayUrl', 'photosUrl', 'requestId', 'hostFamilyId', '$sce', 'InterestedHostFamilyDocuments', 'ExternalResource', 'environment']
    constructor(private $scope, public applicationId, private $state, public InterestedHostFamiliesDetails, private $stateParams, private proposalId, private email, private essayUrl, private photosUrl, private requestId, public hostFamilyId, public $sce, public InterestedHostFamilyDocuments, public ExternalResource, public environment) {
            
        if (photosUrl == undefined && email == undefined && essayUrl == undefined && hostFamilyId == undefined) {
            InterestedHostFamilyDocuments.query({
                applicationId: this.applicationId,
                proposalId: proposalId,
                requestId: requestId
            })
                .$promise
                .then((result) => {
                    this.interestedHFamilyData = result;
                    this.familyEmail = this.interestedHFamilyData.email;
                    this.familyPhotosUrl = this.interestedHFamilyData.photosURL;
                    this.familyEssayUrl = this.interestedHFamilyData.essayURL;
                    this.familyId = this.interestedHFamilyData.hostFamilyId;

                    this.getPhotosAndEssay();
                });
                
        } else {
            this.familyPhotosUrl = photosUrl;
            this.familyEmail = email;
            this.familyEssayUrl = essayUrl;
            this.familyId = hostFamilyId;
                
            this.getPhotosAndEssay();
        }

        this.familyProposalId = proposalId;
        this.familyRequestId = requestId;          
    }

    getPhotosAndEssay() {
        if (this.familyPhotosUrl) {
            this.ExternalResource.get({ url: this.familyPhotosUrl, type: 'familyPhotos' }).$promise.then((res) => {
                this.photosHtmlPage = this.$sce.trustAsHtml(res.html)
            })
        }

        if (this.familyEssayUrl) {
            this.ExternalResource.get({ url: this.familyEssayUrl, type: 'familyEssay' }).$promise.then((res) => {
                this.essayHtmlPage = this.$sce.trustAsHtml(res.html);
            })
        }
    }
      
}
    
angular
    .module('app')
    .controller('HostFamilyDocumentController', HostFamilyDocumentController)
