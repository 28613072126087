import moment from 'moment';
import { ReviewPage } from '../../../services/ReviewService';
import { SectionController } from '../SectionController';
import { ItemGroupId } from '../../../models/ItemGroup';
import { ReviewItemId } from '../../../models/ReviewItem';

class TravelAndProgramController extends SectionController {

	program: any;
	qualifications: any;
	prematch: any;
	passport: any;
	visa: any;
    displayApproveButton: boolean;
    isOfficeUser: boolean;
    isApplicant: boolean;
    page: ReviewPage;
    applicationStatus: string;

    static override $inject = ['$rootScope', '$scope', '$q', 'dialogs', 'Program', 'Prematch', 'Passport', 'Visa', 'Auth', 'ReevaluateSection', 'review', 'travelAndProgramPage', 'applicationId', 'ApproveChanges', 'applicationObservable'];
    constructor($rootScope, $scope, private $q: ng.IQService, dialogs, private Program, private Prematch, private Passport, private Visa, Auth, ReevaluateSection, review, travelAndProgramPage, applicationId, private ApproveChanges, applicationObservable) {
        super($rootScope, $scope, Auth, ReevaluateSection, review, applicationId, ItemGroupId.TravelAndProgram, applicationObservable, dialogs);

		this.program = travelAndProgramPage.program;
		this.qualifications = travelAndProgramPage.qualifications;
		this.prematch = travelAndProgramPage.prematch;
		this.passport = travelAndProgramPage.passport;
        this.passport.currentDate = moment().startOf('day').toDate();

		this.visa = travelAndProgramPage.visa;
        this.visa.currentDateVisa = moment().startOf('day').toDate();

        this.isOfficeUser = Auth.user.isOfficer;
        this.isApplicant = Auth.user.isApplicant;

        this.page = review.getPage(ItemGroupId.TravelAndProgram);
        this.displayApproveButton = this.page && this.page.approveChange && this.isOfficeUser;

        applicationObservable.subscribe(i => {
            this.applicationStatus = i.status;
        })

        $scope.$watch(() => this.$scope.travelAndProgramFrom.$dirty, () => {
            this.$dirty = this.$scope.travelAndProgramFrom.$dirty
        })

        $scope.$on('travelAndProgram:refresh', (nam, type, data) => {
            this.refreshSection(type, data);
        })
	}

    refreshSection(type, data) {
        switch (type) {
            case ReviewItemId.Program:
                this.program = data;
                break;
            case ReviewItemId.PreMatch:
                this.prematch = data;
                break;
            case ReviewItemId.YourPassport:
                this.passport = data;
                this.passport.currentDate = moment().startOf('day').toDate();
                break;
            case ReviewItemId.Visa:
                this.visa = data;
                this.visa.currentDateVisa = moment().startOf('day').toDate();
                break;
            default:
                break;
        }
    }

	save() {
		this.$scope.$broadcast('submitted', true);
		var promisses = [];
		var form = this.$scope.travelAndProgramFrom;
        
        if (form['Passport'].$dirty && (!this.passport.firstName || !this.passport.lastName || !this.passport.birthDate)) {
            this.alerts.push({
                type: 'warning',
                msg: 'Please provide a valid first name, last name and date of birth in the passport section to continue.',
                dismissOnTimeout: 5000
            })

            return;
        }

        var isNameChange = form['Passport'].firstName.$dirty || form['Passport'].lastName.$dirty


        if (form['ProgramSelection'].$dirty || form['Program'].$dirty) {
            this.program.metaform = form['Program'].$metaformVersion;
			promisses.push(
                this.Program.update(this.program)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.Program))
            );
		}

		if (form['PreMatch'].$dirty) {
            this.prematch.metaform = form['PreMatch'].$metaformVersion;
            promisses.push(
                this.Prematch.update(this.prematch)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.PreMatch))
            );
		}

		if (form['Passport'].$dirty) {
            this.passport.metaform = form['Passport'].$metaformVersion;
			promisses.push(
                this.Passport.update(this.passport)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.YourPassport))
            );
		}

		if (form['Visa'].$dirty) {
            this.visa.metaform = form['Visa'].$metaformVersion;
			promisses.push(
                this.Visa.update(this.visa)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.Visa))
            );
		}

		this.$q.all(promisses)
			.then(() => {
                form.$setPristine();

                if (isNameChange) {
                    this.Auth.updateUser({
                        firstName: this.passport.firstName,
                        lastName: this.passport.lastName
                    })
                }

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })
			}, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
			})
    }

    makechange() {
        //var data = {
        //    formDirty: (form && form.$dirty),
        //    noteExists: false,
        //    sectionName: '',
        //    noteId: undefined
        //};

        //if ($scope.ctrl.page) {
        //    var pendingNoteSection = $scope.ctrl.page.items.filter(x => x.notes.some(n => n.status === 0));

        //    if ($scope.ctrl && !$scope.ctrl.isOfficeUser && pendingNoteSection.length > 0) {
        //        data.noteExists = true;
        //        data.sectionName = pendingNoteSection[0].menuItem.name;
        //        data.noteId = pendingNoteSection[0].notes[0].id;
        //    }
        //}
    }

    changesApproved() {

        var user = this.Auth.user
        var data = {
            applicationId: this.applicationId,
            item: ItemGroupId.TravelAndProgram,
            author: user.firstName + ' ' + user.lastName
        };

        this.ApproveChanges
            .update({ applicationId: this.applicationId }, data)
            .$promise
            .then(data => {
                if (this.displayApproveButton === true) {
                    this.displayApproveButton = false;
                    this.page.approveChange = false;
                }

                this.alerts.push({
                    type: 'success',
                    msg: 'Approval complete',
                    dismissOnTimeout: 5000
                });
            }, () => {
                this.alerts.push({
                    type: 'danger',
                    msg: 'We are sorry but this form could not be approved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            });
    }
}

angular
	.module('app')
	.controller('TravelAndProgramController', TravelAndProgramController)