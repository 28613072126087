var servers = {
    activity: `/api/`,
    agency: `/api/`,
    applicant: `/api/`,
    application: `/api/`,
    calendar: `/api/`,
    finance: `/api/`,
    gdpr: `/api/`,
    lookup: `/api/`,
    references: `/api/`,
    registration: `/api/`,
    authentication: `/api/`,
    review: `/api/`,
    messages: `/api/`,
    notifications: `/api/`,
    upload: `/api/`,
    communication: `/api/`,
    users: `/api/`,
    resources: `/api/`,
    share: `/api/`,
    report: `/api/`,
    usAuPair: `/api/usaupair/`,
    signalR: `/api/`,
    //signalR: `http://localhost:9998/`,

    metaform: '/metaform/'
};

angular
    .module('app')
    .constant('apiRoutes', servers);