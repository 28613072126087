
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
import { RoutingProvider, state } from '../../services/RoutingService';
import { AccessLevelView } from '../accessLevel';

import { HostFamiliesController } from '../../areas/hostFamilies/HostFamiliesController';
import { InterestedFamiliesController } from '../../areas/hostFamilies/interestedFamilies/InterestedFamiliesController';
import { InterestedFamilyPageController } from '../../areas/hostFamilies/interestedFamilyPage/InterestedFamilyPageController';
import { HostFamilyDocumentController } from '../../areas/hostFamilies/hostFamilyDocument/HostFamilyDocumentController';

class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {



        const hostFamilies: state<AccessLevelView> = {
            name: 'hostFamilies',
            url: '/hostFamilies',
            abstract: true,
            views: {
                content: {
                    controllerAs: '$ctrl',
                    applicant: {
                        controller: HostFamiliesController,
                        template: 'areas/hostFamilies/applicant.html'
                    }
                }
            },
            resolve: {
                //applicationId: ['$stateParams', 'Auth', function ($stateParams, Auth) {
                //    if ($stateParams.applicationId) {
                //        return $stateParams.applicationId;
                //    } else if (Auth.user && Auth.user.roleDetails && Auth.user.roleDetails.applicationId) {
                //        return Auth.user.roleDetails.applicationId;
                //    }
                //}],
                //applicationInstanceManager: ['ApplicationInstance', 'applicationId', function (ApplicationInstance, applicationId) {
                //    if (applicationId) {
                //        return ApplicationInstance(applicationId);
                //    }
                //}],
                //applicationInstance: ['applicationInstanceManager', function (applicationInstanceManager) {
                //    if (applicationInstanceManager) {
                //        return applicationInstanceManager.instance.$promise;
                //    }
                //}],
                $title: () => 'Host families'
            },
            states: [
                {
                    name: 'interestedFamilies',
                    url: '',
                    views: {
                        content: {
                            controllerAs: '$ctrl',
                            loggedin: {
                                controller: InterestedFamiliesController,
                                template: 'areas/hostFamilies/interestedFamilies/interestedFamilies.html'
                            }
                        }
                    }
                },
                {
                    name: 'hostFamilyDocuments',
                    url: '/hostFamilyDocuments/proposal/:proposalId/request/:requestId',
                    resolve: {
                        proposalId: ['$stateParams', function ($stateParams) {
                            return $stateParams.proposalId;
                        }],
                        photosUrl: ['$stateParams', function ($stateParams) {
                            return $stateParams.photosUrl;
                        }],
                        essayUrl: ['$stateParams', function ($stateParams) {
                            return $stateParams.essayUrl;
                        }],
                        email: ['$stateParams', function ($stateParams) {
                            return $stateParams.email;
                        }],
                        requestId: ['$stateParams', function ($stateParams) {
                            return $stateParams.requestId;
                        }],
                        hostFamilyId: ['$stateParams', function ($stateParams) {
                            return $stateParams.hostFamilyId;
                        }]
                    },
                    params: {
                        proposalId: null,
                        photosUrl: null,
                        essayUrl: null,
                        email: null,
                        requestId: null,
                        hostFamilyId: null
                    },
                    views: {
                        content: {
                            controllerAs: '$ctrl',
                            loggedin: {
                                controller: HostFamilyDocumentController,
                                template: 'areas/hostFamilies/hostFamilyDocument/hostFamilyDocument.html'
                            }
                        }
                    }
                }
            ]
        };

        const hostFamiliesForStuff: state<AccessLevelView> = {
            name: 'hostFamilies',
            url: '/hostFamilies',
            abstract: true,
            views: {
                '': {
                    controllerAs: '$ctrl',
                    loggedin: {
                        controller: HostFamiliesController,
                        template: 'areas/hostFamilies/office.html'
                    }
                }
            },
            resolve: {
                $title: () => 'Host families'
            },
            states: [
                {
                    name: 'interestedFamilies',
                    url: '',
                    views: {
                        content: {
                            controllerAs: '$ctrl',
                            loggedin: {
                                controller: InterestedFamiliesController,
                                template: 'areas/hostFamilies/interestedFamilies/interestedFamilies.html'
                            }
                        }
                    }
                },
                {
                    name: 'hostFamilyDocuments',
                    url: '/hostFamilyDocuments/proposal/:proposalId/request/:requestId',
                    resolve: {
                        proposalId: ['$stateParams', function ($stateParams) {
                            return $stateParams.proposalId;
                        }],
                        photosUrl: ['$stateParams', function ($stateParams) {
                            return $stateParams.photosUrl;
                        }],
                        essayUrl: ['$stateParams', function ($stateParams) {
                            return $stateParams.essayUrl;
                        }],
                        email: ['$stateParams', function ($stateParams) {
                            return $stateParams.email;
                        }],
                        requestId: ['$stateParams', function ($stateParams) {
                            return $stateParams.requestId;
                        }],
                        hostFamilyId: ['$stateParams', function ($stateParams) {
                            return $stateParams.hostFamilyId;
                        }],
                        applicationId: ['$stateParams', function ($stateParams) {
                            return $stateParams.applicationId;
                        }]
                    },
                    params: {
                        proposalId: null,
                        photosUrl: null,
                        essayUrl: null,
                        email: null,
                        requestId: null,
                        hostFamilyId: null,

                        applicationId: null
                    },
                    views: {
                        content: {
                            controllerAs: '$ctrl',
                            loggedin: {
                                controller: HostFamilyDocumentController,
                                template: 'areas/hostFamilies/hostFamilyDocument/hostFamilyDocument.html'
                            }
                        }
                    }
                }
            ]
        };

        var hostfamily: state<AccessLevelView> = {
            name: 'hostFamilyPage',
            url: '/hostFamilyPage',
            views: {
                '@': {
                    loggedin: {
                        controller: InterestedFamilyPageController,
                        template: 'areas/interestedFamilyPage/interestedFamilyPage.html'
                    }
                }
            },
            resolve: {
                placementDetails: ['PlacementDetails', 'applicationId', function (PlacementDetails, applicationId: number) {
                    return PlacementDetails.get({ applicationId: applicationId }).$promise.then(null, r => <any>{
                        content: r.data
                    });
                }]
            }
        };

        RoutingProvider.addState(hostFamilies, 'app');
        RoutingProvider.addState(hostFamiliesForStuff, 'app.applications');
        RoutingProvider.addState(hostfamily, 'app.applications');
    }
}

angular
    .module('app')
    .config(RoutingConfig);