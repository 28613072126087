
class HostFamilyFeedbackModalController {

    public selectedReason: any;
    public reasons: any[];
    public wasInterviewed: any;
    public interestedHFamilyProposal: any[];
    public reasonToDeclineCode: any;
    public code: any;
    public hasInterviewed: any = true;
    public hasReason: any = true;

    public data: any;

    static $inject = ['$scope', 'applicationId', '$uibModalInstance', 'DeclineInterestedHostFamilyReasons', 'InterestedHostFamilyFeedback', 'proposalId', 'interested', 'requestId', 'hostFamilyId', 'familyFullName', 'dialogs', 'InterestedHostFamilyDocuments']
    constructor(private $scope, public applicationId, private $uibModalInstance, public DeclineInterestedHostFamilyReasons, public InterestedHostFamilyFeedback, public proposalId, public interested, public requestId, public hostFamilyId, private familyFullName, private dialogs, public InterestedHostFamilyDocuments) {


        this.data = {
            applicationId: this.applicationId,
            interested: this.interested,
            hostFamilyId: this.hostFamilyId,
            requestId: this.requestId,
            proposalId: this.proposalId
        }
    }

    close() {
        this.$uibModalInstance.dismiss();
    }

    saveInformation() {

        this.$scope.$broadcast('submitted', true)
            
        if (!this.interested) {
            if (this.data.$validation()) {

                this.save().then(() => {
                    this.$uibModalInstance.close();

                    this.dialogs.notify('You have declined', this.familyFullName)
                });
            }
        } else {
            this.save().then(() => {
                this.$uibModalInstance.close();
            })
        }
    }

    save() {
        return this.InterestedHostFamilyFeedback.save(this.data).$promise
    }
}

angular
    .module('app')
    .controller('HostFamilyFeedbackModalController', HostFamilyFeedbackModalController)