// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
import { RoutingProvider, state } from '../../services/RoutingService';
import { AccessLevelView } from '../accessLevel';
import { ResourceGroupEnum } from '../../models/ResourceGroup';
import { NewsArchiveController } from '../../areas/resource/newsArchive/NewsArchiveController'
import { HealthAndWellBeingController } from '../../areas/resource/helpText/healthAndWellBeing/HealthAndWellBeingController'

class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {

        const workQueues: state<AccessLevelView> = {
            name: 'resource',
            url: '/resource',
            abstract: true,
            resolve: {
                resourceGroupId: [function () {
                    return ResourceGroupEnum.Applicant;
                }],
                agencyId: ['Auth', function (Auth) { 
                    return Auth.user && Auth.user.roleDetails && Auth.user.roleDetails.agencyId;
                }],
                $title: () => 'Resources'
            },
            views: {
                content: {
                    applicant: {
                        template: 'areas/resource/applicant.html'
                    },
                    staff: {
                        controller: 'ResourcesGroupController as ctrl',
                        template: 'areas/resource/staff.html'
                    }
                }
            },
            states: [
                {
                    name: 'helpText',
                    url: '/helpText',
                    abstract: true,
                    views: {
                        '': {
                            staff: {
                                template: 'templates/empty.html'
                            }
                        }
                    },
                    states: [
                        {
                            name: 'highlights',
                            url: '/highlights',
                            resolve: {
                                aboutYou: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'AboutYou', agencyId: agencyId });
                                }]
                            },
                            views: {
                                '': {
                                    staff: {
                                        controller: 'HelpText.HighlightsController as ctrl',
                                        template: 'areas/resource/helpText/highlights/highlights.html'
                                    },
                                }
                            }
                        },
                        {
                            name: 'personalDetails',
                            url: '/personalDetails',
                            resolve: {
                                availability: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'Availability', agencyId: agencyId });
                                }],
                                contactDetails: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'ContactDetails', agencyId: agencyId });
                                }],
                                emergencyContact: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'EmergencyContactDetails', agencyId: agencyId });
                                }],
                                drivingDetails: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'DrivingDetails', agencyId: agencyId });
                                }]
                            },
                            views: {
                                '': {
                                    staff: {
                                        controller: 'HelpText.PersonalDetailsController as ctrl',
                                        template: 'areas/resource/helpText/personalDetails/personalDetails.html'
                                    },
                                }
                            }
                        },
                        {
                            name: 'travelAndProgram',
                            url: '/travelAndProgram',
                            resolve: {
                                program: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'Program', agencyId: agencyId });
                                }],
                                prematch: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'PreMatch', agencyId: agencyId });
                                }],
                                passport: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'Passport', agencyId: agencyId });
                                }],
                                visa: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'Visa', agencyId: agencyId });
                                }],
                            },
                            views: {
                                '': {
                                    staff: {
                                        controller: 'HelpText.TravelAndProgramController as ctrl',
                                        template: 'areas/resource/helpText/travelAndProgram/travelAndProgram.html'
                                    },
                                }
                            }
                        },
                        {
                            name: 'childcare',
                            url: '/childcare',
                            resolve: {
                                preferences: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'ChildcareExperience', agencyId: agencyId });
                                }],
                                previousExperience: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'PreviousExperience', agencyId: agencyId });
                                }]
                            },
                            views: {
                                '': {
                                    staff: {
                                        controller: 'HelpText.ChildcareController as ctrl',
                                        template: 'areas/resource/helpText/childcare/childcare.html'
                                    },
                                }
                            }
                        },
                        {
                            name: 'educationAndWork',
                            url: '/educationAndWork',
                            resolve: {
                                plans: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'EducationalHistory', agencyId: agencyId });
                                }]
                            },
                            views: {
                                '': {
                                    staff: {
                                        controller: 'HelpText.EducationAndWorkController as ctrl',
                                        template: 'areas/resource/helpText/educationAndWork/educationAndWork.html'
                                    },
                                }
                            }
                        },
                        {
                            name: 'medicalAndCriminal',
                            url: '/medicalAndCriminal',
                            resolve: {
                                medical: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'MedicalAndHealth', agencyId: agencyId });
                                }],
                                smoking: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'SmokingDeclaration', agencyId: agencyId });
                                }],
                                criminal: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'PoliceRecord', agencyId: agencyId });
                                }]
                            },
                            views: {
                                '': {
                                    staff: {
                                        controller: 'HelpText.MedicalAndCriminalController as ctrl',
                                        template: 'areas/resource/helpText/medicalAndCriminal/medicalAndCriminal.html'
                                    },
                                }
                            }
                        },
                        {
                            name: 'healthAndWellBeing',
                            url: '/healthAndWellBeing',
                            resolve: {
                                allergies: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'Allergies', agencyId: agencyId });
                                }],
                                physicalHealth: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'PhysicalHealth', agencyId: agencyId });
                                }],
                                mentalHealth: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'MentalHealth', agencyId: agencyId });
                                }],
                                medicalTreatments: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'MedicalTreatments', agencyId: agencyId });
                                }],
                                otherHealthConditions: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'OtherHealthConditions', agencyId: agencyId });
                                }],
                                restrictedHealthInformation: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'RestrictedHealthInformation', agencyId: agencyId });
                                }],
                                vaccinations: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'Vaccinations', agencyId: agencyId });
                                }]

                            },
                            views: {
                                '': {
                                    controllerAs: '$ctrl',
                                    staff: {
                                        controller: HealthAndWellBeingController,
                                        template: 'areas/resource/helpText/healthAndWellBeing/healthAndWellBeing.html'
                                    },
                                }
                            }
                        },
                        {
                            name: 'reference',
                            url: '/references',
                            resolve: {
                                references: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'References', agencyId: agencyId });
                                }]
                            },
                            views: {
                                '': {
                                    staff: {
                                        controller: 'HelpText.ReferenceController as ctrl',
                                        template: 'areas/resource/helpText/reference/reference.html'
                                    },
                                }
                            }
                        },
                        {
                            name: 'interviewReport',
                            url: '/interviewReport',
                            resolve: {
                                about: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'InterviewReportAbout', agencyId: agencyId });
                                }],
                                english: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'InterviewReportEnglishSkills', agencyId: agencyId });
                                }],
                                childcare: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'InterviewReportChildcareExperience', agencyId: agencyId });
                                }],
                                motivation: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'InterviewReportMotivation', agencyId: agencyId });
                                }],
                                academicStatus: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'AcademicStatus', agencyId: agencyId });
                                }],
                                personality: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'InterviewReportPersonality', agencyId: agencyId });
                                }],
                                evaluation: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'InterviewReportEvaluation', agencyId: agencyId });
                                }],
                                returner: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'InterviewReportPreviousExperience', agencyId: agencyId });
                                }],
                                medical: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'InterviewReportMedicalCriminal', agencyId: agencyId });
                                }],
                                referral: ['HelpTexts', 'agencyId', function (HelpTexts, agencyId) {
                                    return HelpTexts.get({ module: 'InterviewReportAmbassadorReferral', agencyId: agencyId });
                                }],
                            },
                            views: {
                                '': {
                                    staff: {
                                        controller: 'HelpText.InterviewReportController as ctrl',
                                        template: 'areas/resource/helpText/interviewReport/interviewReport.html'
                                    },
                                }
                            }
                        }
                    ]
                },
                {
                    name: 'applicantResources',
                    url: '/applicantResources',
                    resolve: {
                        resourceGroupId: [function () {
                            return ResourceGroupEnum.Applicant;
                        }]
                    },
                    views: {
                        '': {
                            applicant: {
                                controller: 'ResourcesGroupController as ctrl',
                                template: 'areas/resource/resourcesGroup/applicant.html'
                            },
                            staff: {
                                controller: 'ResourcesGroupController as ctrl',
                                template: 'areas/resource/resourcesGroup/staff.html'
                            }
                        }
                    }
                },
                {
                    name: 'agencyResources',
                    url: '/agencyResources',
                    resolve: {
                        resourceGroupId: [function () {
                            return ResourceGroupEnum.Agency;
                        }]
                    },
                    views: {
                        '': {
                            applicant: {
                                controller: 'ResourcesGroupController as ctrl',
                                template: 'areas/resource/resourcesGroup/applicant.html'
                            },
                            staff: {
                                controller: 'ResourcesGroupController as ctrl',
                                template: 'areas/resource/resourcesGroup/staff.html'
                            }
                        }
                    }
                },
                {
                    name: 'interviewerResources',
                    url: '/interviewerResources',
                    resolve: {
                        resourceGroupId: [function () {
                            return ResourceGroupEnum.Interviewer;
                        }]
                    },
                    views: {
                        '': {
                            applicant: {
                                controller: 'ResourcesGroupController as ctrl',
                                template: 'areas/resource/resourcesGroup/applicant.html'
                            },
                            staff: {
                                controller: 'ResourcesGroupController as ctrl',
                                template: 'areas/resource/resourcesGroup/staff.html'
                            }
                        }
                    }
                },
                {
                    name: 'announcements',
                    url: '/announcements',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: NewsArchiveController,
                                template: 'areas/resource/newsArchive/newsArchive.html'
                            }
                        }
                    }
                }
            ]
        };

        const resourceForApplication: state<AccessLevelView> = {
            name: 'resource',
            url: '/resource',
            abstract: true,
            views: {
                '': {
                    staff: {
                        template: 'templates/empty.html'
                    }
                }
            },
            states: [
                {
                    name: 'applicantResources',
                    url: '',
                    abstract: false,
                    resolve: {
                        resourceGroupId: [function () {
                            return ResourceGroupEnum.Applicant;
                        }],
                        $title: () => 'Resources'
                    },
                    views: {
                        '': {
                            staff: {
                                controller: 'ResourcesGroupApplicantController as ctrl',
                                template: 'areas/resource/resourcesGroup/applicantsResourceForStaff.html'
                            }
                        }
                    }
                }
            ]
        };

        RoutingProvider.addState(workQueues, 'app');
        RoutingProvider.addState(resourceForApplication, 'app.applications');
    }
}

angular
    .module('app')
    .config(RoutingConfig);