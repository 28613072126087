
class ReferenceController {
        
    saved: boolean;

    static $inject = ['$scope', '$q', 'dialogs', 'HelpTextsAdmin', 'references'];
    constructor(private $scope, private $q, private dialogs, private HelpTextsAdmin, private references) {

    }

    save() {
        this.$scope.$broadcast('submitted', true);

        var promisses = [];
        var form = this.$scope.interviewReportForm;
        if (form['References'].$dirty) {
            promisses.push(
                this.HelpTextsAdmin.save({ module: 'References' }, this.references).$promise);
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();
                $('html, body').animate({ scrollTop: 860 }, 200);
                this.saved = true;
            }, () => {
                this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>');
            });
    }
}

angular
    .module('app')
    .controller('HelpText.ReferenceController', ReferenceController);