import { BusinessArea } from '../services/AuthService'

// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
    
export class MenuConfig {

    static $inject = ['MenuProvider']
    constructor(MenuProvider) {

        //Main menu 

        MenuProvider.AddMenu('anon', {
            items: [
                {
                    name: 'Log In',
                    icon: '',
                    state: 'app.login'
                },
                {
                    name: 'Register',
                    icon: '',
                    state: 'app.register'
                },
                {
                    name: 'Find Out More',
                    icon: '',
                    state: 'app.brochure'
                }
            ]
        })

        MenuProvider.AddMenu('awlApplicant', {
            items: [
                {
                    name: 'Home',
                    state: 'app.home'
                },
                {
                    name: 'Flights',
                    state: 'app.flight.flightOption',
                    linkStage: 'app.flight'
                }
            ]
        })

        MenuProvider.AddMenu('preloggedin', {
            items: [
                {
                    name: 'Home',
                    state: 'app.home'
                },
                {
                    name: 'Messages',
                    state: 'app.messages',
                    badge: ['Communication', function (Communication) {
                        return Communication
                            .promise
                            .then((communication) => communication.totalUnreadMessages());
                    }]
                }
            ]
        })

        MenuProvider.AddMenu('preInterview', {
            items: [
                {
                    name: 'Home',
                    state: 'app.home'
                },
                {
                    name: 'Messages',
                    state: 'app.messages',
                    badge: ['Communication', function (Communication) {
                        return Communication
                            .promise
                            .then((communication) => communication.totalUnreadMessages());
                    }]
                }
            ]
        })

        MenuProvider.AddMenu('invalidApplicant', {
            items: [
                {
                    name: 'Home',
                    state: 'app.home'
                },
                {
                    name: 'Messages',
                    state: 'app.messages',
                    badge: ['Communication', function (Communication) {
                        return Communication
                            .promise
                            .then((communication) => communication.totalUnreadMessages());
                    }]
                }
            ]
        })

        MenuProvider.AddMenu('extensionFlightApplicant', {
            items: [
                {
                    name: 'Home',
                    state: 'app.home'
                },
                {
                    name: 'Application',
                    state: 'app.applications.inst.highlights',
                    linkStage: 'app.applications.inst',
                    param: ['Auth', function (Auth) {
                        return {
                            applicationId: Auth.user && Auth.user.roleDetails && Auth.user.roleDetails.applicationId
                        };
                    }]
                },
                {
                    name: 'Host Families',
                    state: 'app.hostFamilies.interestedFamilies',
                    linkStage: 'app.hostFamilies',
                    badge: ['InterestedHostFamiliesDetails', 'Auth', function (InterestedHostFamiliesDetails, Auth) {
                        return InterestedHostFamiliesDetails.count({
                            applicationId: Auth.user && Auth.user.roleDetails && Auth.user.roleDetails.applicationId
                        }).$promise;
                    }]
                },
                {
                    name: 'Placement',
                    state: 'app.placement.placementDetails',
                    linkStage: 'app.placement',
                },
                {
                    name: 'Payments',
                    state: 'app.payments.invoiceDetails',
                    linkStage: 'app.payments'
                },
                {
                    name: 'Resources',
                    state: 'app.resource.applicantResources',
                    linkStage: 'app.resource'
                },
                {
                    name: 'Messages',
                    state: 'app.messages',
                    badge: ['Communication', function (Communication) {
                        return Communication
                            .promise
                            .then((communication) => communication.totalUnreadMessages());
                    }]
                },
                {
                    name: 'Extension',
                    state: 'app.extension.extendOption',
                    linkStage: 'app.extension'
                },
                {
                    name: 'Flights',
                    state: 'app.flight.flightOption',
                    linkStage: 'app.flight'
                }
            ]
        })

        MenuProvider.AddMenu('extensionApplicant', {
            items: [
                {
                    name: 'Home',
                    state: 'app.home'
                },
                {
                    name: 'Application',
                    state: 'app.applications.inst.highlights',
                    linkStage: 'app.applications.inst',
                    param: ['Auth', function (Auth) {
                        return {
                            applicationId: Auth.user && Auth.user.roleDetails && Auth.user.roleDetails.applicationId
                        };
                    }]
                },
                {
                    name: 'Host Families',
                    state: 'app.hostFamilies.interestedFamilies',
                    linkStage: 'app.hostFamilies',
                    badge: ['InterestedHostFamiliesDetails', 'Auth', function (InterestedHostFamiliesDetails, Auth) {
                        return InterestedHostFamiliesDetails.count({
                            applicationId: Auth.user && Auth.user.roleDetails && Auth.user.roleDetails.applicationId
                        }).$promise;
                    }]
                },
                {
                    name: 'Placement',
                    state: 'app.placement.placementDetails',
                    linkStage: 'app.placement',
                },
                {
                    name: 'Payments',
                    state: 'app.payments.invoiceDetails',
                    linkStage: 'app.payments'
                }, 
                {
                    name: 'Resources',
                    state: 'app.resource.applicantResources',
                    linkStage: 'app.resource'
                },
                {
                    name: 'Messages',
                    state: 'app.messages',
                    badge: ['Communication', function (Communication) {
                        return Communication
                            .promise
                            .then((communication) => communication.totalUnreadMessages());
                    }]
                },
                {
                    name: 'Extension',
                    state: 'app.extension.extendOption',
                    linkStage: 'app.extension'
                }
            ]
        })

        MenuProvider.AddMenu('flightApplicant', {
            items: [
                {
                    name: 'Home',
                    state: 'app.home'
                },
                {
                    name: 'Application',
                    state: 'app.applications.inst.highlights',
                    linkStage: 'app.applications.inst',
                    param: ['Auth', function (Auth) {
                        return {
                            applicationId: Auth.user && Auth.user.roleDetails && Auth.user.roleDetails.applicationId
                        };
                    }]
                },
                {
                    name: 'Host Families',
                    state: 'app.hostFamilies.interestedFamilies',
                    linkStage: 'app.hostFamilies',
                    badge: ['InterestedHostFamiliesDetails', 'Auth', function (InterestedHostFamiliesDetails, Auth) {
                        return InterestedHostFamiliesDetails.count({
                            applicationId: Auth.user && Auth.user.roleDetails && Auth.user.roleDetails.applicationId
                        }).$promise;
                    }]
                },
                {
                    name: 'Placement',
                    state: 'app.placement.placementDetails',
                    linkStage: 'app.placement',
                },
                {
                    name: 'Payments',
                    state: 'app.payments.invoiceDetails',
                    linkStage: 'app.payments'
                },
                {
                    name: 'Resources',
                    state: 'app.resource.applicantResources',
                    linkStage: 'app.resource'
                },
                {
                    name: 'Messages',
                    state: 'app.messages',
                    badge: ['Communication', function (Communication) {
                        return Communication
                            .promise
                            .then((communication) => communication.totalUnreadMessages());
                    }]
                },
                {
                    name: 'Flights',
                    state: 'app.flight.flightOption',
                    linkStage: 'app.flight'
                }
            ]
        })

        MenuProvider.AddMenu('applicant', {
            items: [
                {
                    name: 'Home',
                    state: 'app.home'
                },
                {
                    name: 'Application',
                    state: 'app.applications.inst.highlights',
                    linkStage: 'app.applications.inst',
                    param: ['Auth', function (Auth) {
                        return {
                            applicationId: Auth.user && Auth.user.roleDetails && Auth.user.roleDetails.applicationId
                        };
                    }]
                },
                {
                    name: 'Host Families',
                    state: 'app.hostFamilies.interestedFamilies',
                    linkStage: 'app.hostFamilies',
                    badge: ['InterestedHostFamiliesDetails', 'Auth', function (InterestedHostFamiliesDetails, Auth) {
                        return InterestedHostFamiliesDetails.count({
                            applicationId: Auth.user && Auth.user.roleDetails && Auth.user.roleDetails.applicationId
                        }).$promise;
                    }]
                },
                {
                    name: 'Placement',
                    state: 'app.placement.placementDetails',
                    linkStage: 'app.placement',
                },
                {
                    name: 'Payments',
                    state: 'app.payments.invoiceDetails',
                    linkStage: 'app.payments'
                },
                {
                    name: 'Resources',
                    state: 'app.resource.applicantResources',
                    linkStage: 'app.resource'
                },
                {
                    name: 'Messages',
                    state: 'app.messages',
                    badge: ['Communication', function (Communication) {
                        return Communication
                            .promise
                            .then((communication) =>  communication.totalUnreadMessages());
                    }]
                }
            ]
        })

        MenuProvider.AddMenu('interviewer', {
            items: [
                {
                    name: 'Home',
                    state: 'app.home'
                },
                {
                    name: 'Applications',
                    state: 'app.workQueue',
                    param: {
                        queueName: ''
                    }
                },
                {
                    name: 'Calendar',
                    state: 'app.calendar'
                },
                {
                    name: 'My Profile',
                    state: 'app.profile.myProfile'
                },
                {
                    name: 'Resources',
                    state: 'app.resource.interviewerResources'
                },
                {
                    name: 'Messages',
                    state: 'app.messages',
                    badge: ['Communication', function (Communication) {
                        return Communication
                            .promise
                            .then((communication) => communication.totalUnreadMessages());
                    }]
                },
                {
                    name: 'Reports',
                    state: 'app.reports'
                }
            ]
        })

        MenuProvider.AddMenu('branch', {
            items: [
                {
                    name: 'Home',
                    state: 'app.home'
                },
                {
                    name: 'Applications',
                    state: 'app.workQueue',
                    param: {
                        queueName: ''
                    }
                },
                {
                    name: 'Calendar',
                    state: 'app.calendar'
                },
                {
                    name: 'My Profile',
                    state: 'app.profile.myProfile'
                },
                {
                    name: 'Resources',
                    state: 'app.resource.interviewerResources'
                },
                {
                    name: 'Messages',
                    state: 'app.messages',
                    badge: ['Communication', function (Communication) {
                        return Communication
                            .promise
                            .then((communication) => communication.totalUnreadMessages());
                    }]
                },
                {
                    name: 'Reports',
                    state: 'app.reports'
                }
            ]
        })

        MenuProvider.AddMenu('agency', {
            items: [
                {
                    name: 'Home',
                    state: 'app.home'
                },
                {
                    name: 'Applications',
                    state: 'app.workQueue',
                    param: {
                        queueName: ''
                    }
                },
                {
                    name: 'Calendar',
                    state: 'app.calendar'
                },
                {
                    name: 'My Profile',
                    state: 'app.profile.myProfile'
                },
                {
                    name: 'Resources',
                    state: 'app.resource.agencyResources'
                },
                {
                    name: 'Management',
                    state: 'app.management.basicDetails',
                    badge: ['AgencyContract', 'Auth', function (AgencyContract, Auth) {
                        return AgencyContract
                            .getContractInformationForMenu({ agencyId: Auth.user.roleDetails.agencyId })
                            .$promise
                            .then((r) =>
                            {
                                if ((r.canViewContract || r.canAcceptContract) && r.showNewContractCount) {
                                    return r.menuCount;
                                }
                                else if (r.canViewContract && r.canAcceptContract && r.showSubmitFees) {
                                    return r.menuCount;
                                }

                                return 0;
                            })
                    }]//,
                    //visibility: ['AgencyContract', 'Auth', function (AgencyContract, Auth) {
                    //    return AgencyContract
                    //        .getContractInformationForMenu({ agencyId: Auth.user.roleDetails.agencyId })
                    //        .$promise
                    //        .then((r) => r.canViewContract || r.canAcceptContract)
                    //}]
                },
                {
                    name: 'Messages',
                    state: 'app.messages',
                    badge: ['Communication', function (Communication) {
                        return Communication
                            .promise
                            .then((communication) =>  communication.totalUnreadMessages());
                    }]
                },
                {
                    name: 'Reports',
                    state: 'app.reports'
                }
            ]
        })

        MenuProvider.AddMenu('staff', {
            items: [
                {
                    name: 'Home',
                    state: 'app.home'
                },
                {
                    name: 'Applications',
                    state: 'app.workQueue',
                    param: {
                        queueName: ''
                    }
                },
                {
                    name: 'Agency',
                    state: 'app.agency',
                    param: {
                        queueName: ''
                    }
                },
                {
                    name: 'Contacts',
                    state: 'app.contacts'
                },
                {
                    name: 'Resources',
                    state: 'app.resource.agencyResources'
                },
                {
                    name: 'Reports',
                    state: 'app.reports'
                },
                {
                    name: 'Tools',
                    state: 'app.tools',
                    visibility: ['Auth', function (Auth) {
                        return Auth.isInAnyBusinessAreas(
                            BusinessArea.FINANCE,
                            BusinessArea.ITEP_ADMINS,
                            BusinessArea.COUNTRY_MANAGEMENT,
                            BusinessArea.FLIGHT_MANAGEMENT,
                            BusinessArea.OFFICE_USERS_MANAGEMENT
                        );
                    }]
                },
                {
                    name: 'Altos',
                    state: 'app.altos'
                }
            ]
        })           
    }
}

angular
    .module('app')
    .config(MenuConfig)