
class FlightDetailsController {
    flightDetails: any;
    fullName: string;
    sharedFlightApplicantsSref: string = 'app.placement.sharedFlightContactDetails';
    showSuccessText: boolean = false;
    noFlightDetailAvailable: boolean = false;
    isShareFlightDetail: boolean = false;
    isConfirmed: boolean = false;
    showIsConfirmed: boolean = false;

    isOnlineOrAwaitingOrToBeMatch: boolean;
    isActiveOrRematch: boolean;
    isInactiveOrDepartedOrAWOL: boolean;
    isPlaced: boolean;
    isOfficer: any;

    multipleAirlines: boolean;
    goToAirportDate: Date;
    tfo: any;

    static $inject = ['$scope', 'user', 'ApplicationPlacement', 'dialogs', '$timeout', 'applicationObservable', 'applicationId']
    constructor(private $scope, private user, private ApplicationPlacement, private dialogs, private $timeout, applicationObservable, private applicationId) {
        this.isOfficer = (user.role === 'OFF');
        if (this.isOfficer) {
            this.sharedFlightApplicantsSref = 'app.applications.placement.sharedFlightContactDetails'                
        }


        applicationObservable.subscribe(applicationInstance => {
            this.isOnlineOrAwaitingOrToBeMatch = (applicationInstance.status === 'OLA' || applicationInstance.status === 'AWA' || applicationInstance.status === 'TBM');
            this.isActiveOrRematch = (applicationInstance.status === 'ACT' || applicationInstance.status === 'REM');
            this.isPlaced = (applicationInstance.status === 'PLC');
            this.isInactiveOrDepartedOrAWOL = (applicationInstance.status === 'INA' || applicationInstance.status === 'APD' || applicationInstance.status === 'AWL');
            this.fullName = applicationInstance.fullName;

            if (this.isPlaced) {
                this.ApplicationPlacement.flightDetails({
                    applicationId: this.applicationId
                }).$promise
                    .then((data) => {
                        this.flightDetails = data;

                        this.multipleAirlines = this.checkIfMultipleAirlines(data.flightLegList);

                        var m = moment(data.departDate).utc();
                        var hours = m.hour();
                        if (1 <= hours && hours <= 3) {
                            this.goToAirportDate = m.subtract(1, 'day').toDate();
                        }

                    });
            }
        })            


        this.ApplicationPlacement.travelFromOrientationDetails({
            applicationId: this.applicationId
        }).$promise.then((data) => {
            this.tfo = data;
        });

        this.ApplicationPlacement
            .flightShareDetail({
                applicationId: this.applicationId
            })
            .$promise
            .then((shareFlightDetail) => {
                this.isShareFlightDetail = shareFlightDetail.isShareFlightDetail;
                this.isConfirmed = shareFlightDetail.isConfirmed;

                this.showIsConfirmed = !this.isConfirmed;                    
            });

    }

    checkIfMultipleAirlines(flightLegList) {
        if (!flightLegList || !(flightLegList.length >= 2)) {
            return false;
        }

        let airline = flightLegList[0].airlineCode
        for (let flightLeg of flightLegList) {
            if (flightLeg.airlineCode != airline) {
                return true;
            }
        }

        return false;
    }

    GetFlightShareDetail() {
        this.ApplicationPlacement.flightDetails({
            applicationId: this.applicationId
        }).$promise.then((data) => {
            this.flightDetails = data;
        });
    }

    saveFlightConfirmed() {
        this.isConfirmed = true;

        var data = {
            applicationId: this.applicationId,
            isShareFlightDetail: this.isShareFlightDetail,
            isConfirmed: this.isConfirmed
        };

        this.ApplicationPlacement.saveFlightShareDetail({
            applicationId: this.applicationId
        }, data).$promise.then(() => {
            this.showSuccessText = true;
            this.showIsConfirmed = false;
            this.$timeout(() => { this.showSuccessText = false; }, 3000);
        }, () => {
            this.isConfirmed = false;
            this.dialogs.error('Error', 'We are sorry but your flight confirm details could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>');
        });
    }

    saveFlightShareDetail() {
        var data = {
            applicationId: this.applicationId,
            isShareFlightDetail: this.isShareFlightDetail,
            isConfirmed: this.isConfirmed
        };

        this.ApplicationPlacement.saveFlightShareDetail({
            applicationId: this.applicationId
        }, data).$promise.then(() => {
            this.showSuccessText = true;
            this.$timeout(() => { this.showSuccessText = false; }, 3000);
        }, () => {
            this.isShareFlightDetail = !this.isShareFlightDetail;
            this.dialogs.error('Error', 'We are sorry but your flight share detail could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>');
        });
    }
}

angular
    .module('app')
    .controller('FlightDetailsController', FlightDetailsController);