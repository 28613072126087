import { IAction } from '../IAction'

export class sendBulkMessage implements IAction {

    static $inject = ['$uibModal', 'SendBulkMessage', 'Auth', 'selections'];
    constructor(private $uibModal, private SendBulkMessage, private Auth, private selections) {

	}

    execute = () => {
        let applicationIds = this.selections.map(a => a.applicationId);
        return this.$uibModal.open({
            templateUrl: 'areas/action/bulkMessanger/sendBulkMessageModal.html',
            controller: SendBulkMessageModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                userId: () => this.Auth.user.userId,
                selections: () => this.selections,
                validApplicationIds: () => this.SendBulkMessage.canSend(applicationIds).$promise
			}
		}).result;
    }
        
}

class SendBulkMessageModalController {
    public newMessageText: string;

    static $inject = ['dialogs', '$sce', '$uibModalInstance', 'selections', 'validApplicationIds', 'userId', 'SendBulkMessage', '$state'];
    constructor(private dialogs, private $sce, private $uibModalInstance, private selections, private validApplicationIds, private userId, private SendBulkMessage, private $state) { }

    send() {
        const to = this.selections
            .filter(a => this.validApplicationIds
                .some(vai => vai === a.applicationId))
            .map(a => <any>{ applicationId: a.applicationId, userId: a.userId });

        this.SendBulkMessage
            .send({ from: this.userId, to: to, messageText: this.newMessageText })
            .$promise
            .then((response) => {
            }, (error) => {
                return this.displayError(error);
            }).finally(() => {
                this.$uibModalInstance.close();
            });

        if (to.length >= 500) {
            this.dialogs.notify('Bulk Message', 'Messages are being sent, this will take a few minutes to complete');
        }
	}

    displayError(results) {
        let message = 'Messages cannot be sent due to some technical problem, please try again shortly.</br>';

        return this.dialogs
            .error('Issue occurred while sending messages', this.$sce.trustAsHtml(message))
            .result
    }

	close() {
		this.$uibModalInstance.dismiss();
	}

}
    