import { HubConnectionBuilder, LogLevel, HubConnection } from "@microsoft/signalr";
import { SiteUser } from './siteUser';
import moment from "moment";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

declare global {

    interface SignalR {
        applicationHub: any
        messagesHub: any
        reviewHub: any
        usersHub: any
    }
}


class PushProvider implements angular.IServiceProvider {

    applicationsConnection: HubConnection
    applicationsStartPromise: Promise<void>
    applicationsStopPromise: Promise<void>

    $get = ['$rootScope', 'Auth', 'Communication', 'ApplicationInsights', 'apiRoutes',
        ($rootScope: angular.IScope, Auth, Communication, ApplicationInsights, apiRoutes) => {

            var getToken = () => {
                return new Promise<string>((resolve, reject) => {
                    Auth.getAccessToken().then(token => {
                        var now = new Date();
                        if (token) {
                            //ApplicationInsights.trackTrace({
                            //    message: 'Token is present',
                            //    severityLevel: SeverityLevel.Warning,
                            //    properties: {
                            //        moment: moment().unix(),
                            //        valueOf: now.valueOf(),
                            //        getTime: now.getTime(),
                            //        toISOString: now.toISOString()
                            //    }
                            //});
                            resolve(token)
                        } else {
                            ApplicationInsights.trackTrace({
                                message: 'Token is missing',
                                severityLevel: SeverityLevel.Error,
                                properties: {
                                    moment: moment().unix(),
                                    valueOf: now.valueOf(),
                                    getTime: now.getTime(),
                                    toISOString: now.toISOString()
                                }
                            });
                            reject("access token is missing")
                        }
                    }, e => {
                        var now = new Date();
                        ApplicationInsights.trackTrace({
                            message: 'Failed to get token',
                            severityLevel: SeverityLevel.Error,
                            properties: {
                                moment: moment().unix(),
                                valueOf: now.valueOf(),
                                getTime: now.getTime(),
                                toISOString: now.toISOString()
                            }
                        });
                        reject(e)
                    })
                });
            }


            this.applicationsConnection = new HubConnectionBuilder()
                .withUrl(`${apiRoutes.signalR}signalR`, { accessTokenFactory: getToken })
                .configureLogging(LogLevel.Information)
                .withAutomaticReconnect()
                .build();

            this.applicationsConnection.on('ItemReviewStatusUpdated', function (status) {
                $rootScope.$broadcast('itemReviewStatus:updated', status)
            });
            this.applicationsConnection.on('DocumentReviewStatusUpdated', function (status) {
                $rootScope.$broadcast('documentReviewStatus:updated', status)
            });
            this.applicationsConnection.on('NoteChange', function (noteChange) {
                $rootScope.$broadcast('push:noteChange', noteChange)
            });
            this.applicationsConnection.on('UploadChange', function (uploadChange) {
                $rootScope.$broadcast('push:uploadChange', uploadChange)
            });
            this.applicationsConnection.on('ApplicationStatusChange', function (message) {
                $rootScope.$broadcast('push:applicaionStatusChange', message)
            });
            this.applicationsConnection.on('ApplicationCommentsChange', function (message) {
                $rootScope.$broadcast('push:applicaionCommentsChange', message)
            });
            this.applicationsConnection.on('ItemReviewUpdated', function (message) {
                $rootScope.$broadcast('push:itemReviewUpdated', message)
            });
            this.applicationsConnection.on('SuppressInsuranceChange', function (message) {
                $rootScope.$broadcast('suppressInsuranceChanged');
            });
            this.applicationsConnection.on('ApplicationObserversChange', function (message) {
                $rootScope.$broadcast('push:applicationObserversChange', message);
            });


            



            this.applicationsConnection.on('MessageReceived', function (conversationId) {
                Communication.promise.then(function (communication) {
                    var conversation = communication.conversation(conversationId)
                    conversation.update()
                    if (conversation.messages) {
                        conversation.loadMessages(true)
                    }
                })
            });
            this.applicationsConnection.on('MessageUnreadCountChanged', function (conversationId) {
                Communication.promise.then(function (communication) {
                    communication.conversation(conversationId).update()
                })
            });
            this.applicationsConnection.on('UserActionsUpdate', function (entryType: string, entryId: number, actions: any[]) {
                $rootScope.$broadcast('userActions:update', entryType, entryId, actions)
            });

            this.applicationsConnection.onreconnected(() => {
                $rootScope.$broadcast('push:reconnected');
            });



            // establish connection
            $rootScope.$on('auth:user-change', () => {
                this.establishConnection(Auth)
            })


            this.establishConnection(Auth);
            return {
                openApplication: (applicationId) => {
                    return this.applicationsStartPromise
                        ? this.applicationsStartPromise.then(() => this.applicationsConnection.send('openApplication', { applicationId: applicationId }))
                        : Promise.reject();
                },
                closeApplication: (applicationId) => {
                    return this.applicationsStartPromise
                        ? this.applicationsStartPromise.then(() => this.applicationsConnection.send('closeApplication', { applicationId: applicationId }))
                        : Promise.reject();
                },
                getApplicationObservers: () => {
                    return this.applicationsStartPromise
                        ? this.applicationsStartPromise.then(() => this.applicationsConnection.invoke('getApplicationObservers'))
                        : Promise.reject();
                }
            };
        }]

    startConnection() {

    }

    stopConnecttion() {

    }

    establishConnection(Auth) {
        if (Auth.user) {
            Auth.getAccessToken().then(token => {
                if (token) {
                    if (this.applicationsStopPromise) {
                        this.applicationsStopPromise.then(() => {
                            this.applicationsStartPromise = this.applicationsConnection.start().catch(err => console.error(err));
                        });
                    } else {
                        this.applicationsStartPromise = this.applicationsConnection.start().catch(err => console.error(err));
                    }

                }
            })

        } else {
            this.applicationsStopPromise = this.applicationsConnection.stop();
        }
    }
}




angular
    .module('app')
    .provider('PushHub', PushProvider)