import _ from 'lodash-es'
import { IAction } from '../IAction'

export class CancelApplication implements IAction{

    static $inject = ['$uibModal', '$rootScope', 'applicationId']
    constructor(private $uibModal, private $rootScope, private applicationId) {

    }

    execute = () => {
        return this.$uibModal.open({
            templateUrl: 'areas/action/cancelApplication/CancelApplicationModal.html',
            controller: CancelApplicationModalController,
            controllerAs: 'ctrl',
            backdrop: false,
            resolve: {
                applicationId: () => this.applicationId,
                rootScope: () => this.$rootScope
            }
        }).result;
    }
}

class CancelApplicationModalController {
    public title: string;
    public selectedreason: any;
    public cancellationReasonList: any[];
    public commentText: string;
    public payCommission: boolean;
    public isApplicant: boolean;

    static $inject = ['dialogs', '$q', '$uibModalInstance', 'applicationId', 'CancelApplication', 'Auth', 'rootScope', 'Spinner'];
    constructor(private dialogs, private $q: ng.IQService, private $uibModalInstance, private applicationId, private CancelApplication, private Auth, private rootScope, private Spinner) {
        this.title = Auth.user.isApplicant ? 'Cancel My Application' : 'Cancel Application';

        this.payCommission = true;
        this.isApplicant = Auth.user.isApplicant
        this.loadCancellationList();
    }

    reasonDescription = (inter) => `${inter.statusDescription}`;

    loadCancellationList() {
        this.CancelApplication.query({}, (response) => {
            this.cancellationReasonList = response;
        });
    }

    cancelApplication() {
        this.Spinner.show();

        var user = this.Auth.user;

        if (angular.isObject(this.selectedreason)) {
            var data = {
                ApplicationId: this.applicationId,
                UserName: user.firstName + ' ' + user.lastName,
                SubStatusId: this.selectedreason.subStatusId,
                StatusDescription: this.selectedreason.statusDescription,
                CommentText: this.commentText,
                PayCommission: this.payCommission
            };


            this.CancelApplication.update({ applicationId: this.applicationId }, data)
                .$promise
                .then((results) => {
                    if (_.some(results, (r: any) => r.success == false)) {
                        this.dialogs.error('Internal error', 'Error occured while cancelling this application. Please try again after a while.');
                    }
                    this.rootScope.$broadcast('cancelFailApplication', data);                      
                })
                .finally(() => {
                    this.Spinner.hide();
                    this.$uibModalInstance.close();
                });
        }
        else {
            this.dialogs.error('Validation Error', 'Please choose your reason for cancelling this application, and whether a commission payment should be generated for the agent.');
        }
    }

    close() {
        this.$uibModalInstance.dismiss();
    }

}
