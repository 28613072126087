
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
    
export class MenuConfig {
    static $inject = ['MenuProvider'];
    constructor(MenuProvider) {

        MenuProvider.AddMenu('everyone', 'hostFamilies', {
            items: [
                {
                    name: 'Interested Families',
                    state: 'app.hostFamilies.interestedFamilies'
                },
            ]
        });

        MenuProvider.AddMenu('everyone', 'hostFamiliesOffice', {
            items: [
                {
                    name: 'Interested Families',
                    state: 'app.applications.hostFamilies.interestedFamilies'
                }
            ]
        });

    }
}

angular
    .module('app')
    .config(MenuConfig);