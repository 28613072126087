import _ from 'lodash-es'
import { DataTransformation } from './dataAccess/DataTransformation'
import { ItemGroupId, ItemGroupMenuItem, ItemGroups, StaffItemGroups } from '../models/ItemGroup'
import { DocumentMenuItem, ReviewDocumentId, Documents } from '../models/ReviewDocument'
import { ReviewItemId, ItemMenuItem, Items } from '../models/ReviewItem'


export enum ReviewStatus {
    InProgress = 1,
    SubmittedByInterviewer = 2,
    SubmittedByAgent = 3
}

export enum NoteStatus {
    Raised = 0,
    ChangesMade = 1,
    Dismissed = 2
}

export interface ReviewItem {
    menuItem: ItemMenuItem
    type: ReviewItemId
    approveChange: boolean
    isComplete: boolean
    hasNote: boolean
    metaformVersion?: number

    snapshots: [{
        referenceId: number
        lastViewedSnapshotData: any
        lastViewedSnapshotDate: Date
    }]

    notes: {
        id: number
        assignId?: number
        author: string
        text: string
        date: Date
        status: NoteStatus
    }[]
}

export interface ReviewDocument {
    menuItem: DocumentMenuItem

    documentType: ReviewDocumentId
    status: number // create an enum if we want to handle statuses on the client side // not sure if this is used at all
    isComplete: boolean
    hasNote: boolean
}

export interface ReviewPage {

    group: ItemGroupId
    menuItem: ItemGroupMenuItem

    hasChanges: boolean
    hasNote: boolean
    isComplete: boolean
    isUploadComplete: boolean
    approveChange: boolean

    items: ReviewItem[]
    documents: ReviewDocument[]
}

export class ReviewService {

    $promise

    trans: DataTransformation
    isComplete: boolean
    isUploadComplete: boolean
    hasNotes: boolean
    hasChanges: boolean
    approveChange: boolean
    pages: ReviewPage[]
    reviewStatus: ReviewStatus
    isPageComplete: boolean
        
    constructor(
        private $rootScope: angular.IScope,
        private $q,
        private ReviewablePage,
        public applicationId,
        public userId,
        public role,
        public referenceId,
        public itemId,
        public showAsApplicant) {

        this.trans = new DataTransformation()

        $rootScope.$watchCollection(() => this.pages, () => {
            this.isComplete = this.pages && this.pages.map(page => page.isComplete && page.isUploadComplete).reduce((prev, curr) => prev && curr)
            this.isPageComplete = this.pages && this.pages.every(c => c.isComplete == true);
        })
            
        var recalculatePageCompleteness = function (page: ReviewPage) {
            page.isComplete = page.items.every((item) => item.isComplete);
            page.isUploadComplete = page.documents.every((doc) => doc.isComplete);
            //console.timeEnd('itemReviewStatus:updated:recalculatePageCompleteness');
            $rootScope.$broadcast('reviewService:pageCompletnessChange', page);
        }

        var recalculatePageNotes = function (page: ReviewPage) {
            page.hasNote = page.items.some((item) => item.hasNote)
            $rootScope.$broadcast('reviewService:pageNotesChange', page);
        }

        $rootScope.$on('itemReviewStatus:updated', (event, itemReviewChanges) => {
            //console.time('itemReviewStatus:updated:findReviewItem')

            var page: ReviewPage = this.pages.filter(page => page.items.some(item => item.type === itemReviewChanges.itemId))[0]

            var item: ReviewItem = page.items.filter(item => item.type === itemReviewChanges.itemId)[0]
            //console.timeEnd('itemReviewStatus:updated:findReviewItem')

            //console.time('itemReviewStatus:updated:recalculatePageCompleteness')
            item.isComplete = itemReviewChanges.isComplete
            recalculatePageCompleteness(page)
        })

        $rootScope.$on('reviewService:itemUpdated', (event, itemReviewChanges) => {
            this.refreshData(itemReviewChanges.groupId);
        })

        $rootScope.$on('documentReviewStatus:updated', (event, documentReviewChanges) => {
            //console.time('documentReviewStatus:updated:findReviewDocument')

            var page: ReviewPage = this.pages.filter(page => page.documents.some(doc => doc.documentType === documentReviewChanges.documentType))[0]

            var document: ReviewDocument = page.documents.filter(doc => doc.documentType === documentReviewChanges.documentType)[0]
            //console.timeEnd('documentReviewStatus:updated:findReviewDocument')

            //console.time('documentReviewStatus:updated:recalculatePageCompleteness')
            document.isComplete = documentReviewChanges.isComplete
            recalculatePageCompleteness(page)
        })

        $rootScope.$on('push:noteChange', (event, noteChange) => {
            var page: ReviewPage = this.pages.filter(page => page.items.some(item => item.type === noteChange.reviewItemId))[0]

            var item: ReviewItem = page.items.filter(item => item.type === noteChange.reviewItemId)[0]

            item.hasNote = noteChange.hasNote
            if (!item.notes) {
                item.notes = [noteChange.note];
            } else {
                var added = false;
                for (var i = 0; i < item.notes.length; i++) {
                    if ((item.notes[i].assignId == noteChange.note.assignId) || (!item.notes[i].assignId && !noteChange.note.assignId)) {
                        item.notes[i] = noteChange.note;
                        added = true;
                        break;
                    }
                }
                if (!added) {
                    item.notes.push(noteChange.note);
                }
            }

            recalculatePageNotes(page)
        })

        $rootScope.$on('note:removeNoteByAssignId', (event, data) => {
            var noteInfo, pageInfo, itemInfo;
            for (let page of this.pages) {
                if (page.group === data.groupId && !noteInfo) {
                    for (let item of page.items) {
                        if (item.type === data.itemGroupId && item.notes && !noteInfo) {
                            for (let note of item.notes) {
                                if (note.assignId == data.assignId || (!data.assignId && !note.assignId)) {
                                    noteInfo = note;
                                    pageInfo = page;
                                    itemInfo = item;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            if (noteInfo) {
                noteInfo.status = 2;

                if (itemInfo.notes.filter(note => note.status == 0).length == 0) {
                    itemInfo.hasNote = false;
                    recalculatePageNotes(pageInfo);
                }
            }
        });

        this.getData()
    }

    getData() {

        var deferred = this.$q.defer()
        this.$promise = deferred.promise;

        if (this.showAsApplicant) {
            this.ReviewablePage
                .applicationStatusForApplicant({
                    applicationId: this.applicationId
                })
                .$promise
                .then((status) => {
                    this.processReviewStatusData(status, deferred, ItemGroups);  
                                              
                }, (r) => {
                    deferred.reject()
                })            
        }
        else {
            this.ReviewablePage
                .applicationReviews({
                    applicationId: this.applicationId,
                    referenceId: this.referenceId,
                    itemId: this.itemId
                })
                .$promise
                .then((status) => {
                    this.processReviewStatusData(status, deferred, (this.role == 'APP') ? ItemGroups : StaffItemGroups);

                }, (r) => {
                    deferred.reject()
                })
        }
    }

    refreshData(groupId) {
        var deferred = this.$q.defer()
        this.$promise = deferred.promise;

        //todo get only one item
        this.ReviewablePage
            .applicationStatusForApplicant({
                applicationId: this.applicationId
            })
            .$promise
            .then((status) => {
                //this.processReviewStatusData(status, deferred, ItemGroups);
                var updatedPage = status.pages.filter(page => page.group === groupId)[0];
                this.$rootScope.$broadcast('refreshMenu:itemUpdated', updatedPage);

            }, (r) => {
                deferred.reject()
            })
    }

    processReviewStatusData(status: any, deferred, itemGroups) {
        this.isComplete = status.isComplete
        this.hasNotes = status.hasNotes
        this.approveChange = status.approveChange

        this.hasChanges = false;
        this.isUploadComplete = true;
        

        this.pages = status.pages as ReviewPage[]
        for (let page of this.pages) {

            page.menuItem = itemGroups[page.group]

            for (let item of page.items) {
                item.menuItem = Items[item.type]
            }

            for (let document of page.documents) {
                document.menuItem = Documents[document.documentType]
            }

            if (page.group !== 8 && !page.isUploadComplete) {
                this.isUploadComplete = false;
            }

            if (page.hasNote) {
                this.hasNotes = true;
            }
        }
        
        this.applyTransformation(this.pages);

        deferred.resolve(this)
        this.$rootScope.$broadcast('review:dataChange', this)
    }

    applyTransformation(pages: ReviewPage[]) {
        for (let page of pages) {
            for (let item of page.items) {
                if (!item.snapshots) {
                    continue
                }

                for (let snapshot of item.snapshots) {
                    if (!snapshot.lastViewedSnapshotData) {
                        continue
                    }
                    switch (item.type) {
                        case ReviewItemId.AboutYou:
                            snapshot.lastViewedSnapshotData = this.trans.convertStringToCheckbox('specialDietDetails')(snapshot.lastViewedSnapshotData)
                            break;
                        case ReviewItemId.ContactDetails:
                            snapshot.lastViewedSnapshotData = this.trans.convertStringToCheckbox('preferredMethodContact')(snapshot.lastViewedSnapshotData)
                            break;
                        case ReviewItemId.Allergies:
                            snapshot.lastViewedSnapshotData = this.trans.convertStringToCheckbox('allergy','allergyAnimals')(snapshot.lastViewedSnapshotData)
                            break;
                        case ReviewItemId.Hobbies:
                            snapshot.lastViewedSnapshotData = this.trans.convertCheckboxToArray('hobbies')(snapshot.lastViewedSnapshotData)
                            break;
                        case ReviewItemId.DrivingDetails:
                            snapshot.lastViewedSnapshotData = this.trans.convertStringToCheckbox('roadTypes')(snapshot.lastViewedSnapshotData)
                            break;
                        case ReviewItemId.MentalHealth:
                            snapshot.lastViewedSnapshotData = this.trans.convertStringToCheckbox('counsellingType','selfHarmType','eatingDisorderType','abuseAndAssaultType')(snapshot.lastViewedSnapshotData)
                            break;
                        case ReviewItemId.OtherHealthConditions:
                            snapshot.lastViewedSnapshotData = this.trans.convertStringToCheckbox('neurodevelopmental')(snapshot.lastViewedSnapshotData)
                            break;
                        case ReviewItemId.MedicalAndHealth:
                            snapshot.lastViewedSnapshotData = this.trans.convertStringToCheckbox('medicalCondition', 'dietDetails', 'allergies', 'animalAllergies', 'immunisations', 'specialDietDetails')(snapshot.lastViewedSnapshotData)
                            break;
                        case ReviewItemId.ChildcareExperience:
                            snapshot.lastViewedSnapshotData = this.trans.convertStringToCheckbox('specialNeed')(snapshot.lastViewedSnapshotData)
                            break;
                        case ReviewItemId.InterviewReport_Motivation:
                            snapshot.lastViewedSnapshotData = this.trans.convertStringToCheckbox('householdDuties')(snapshot.lastViewedSnapshotData)
                            break;
                    }
                }
            }
        }
    }

    updateStatus(itemGroup: ItemGroupId) {
        return this.ReviewablePage.updateStatus({
            applicationId: this.applicationId,
            userId: this.userId
        }, {
                itemGroupId: itemGroup
            })
    }


    getPage(groupId): ReviewPage {
        return _.find(this.pages, (p) => p.group == groupId)
    }

    getReviewableItemNote(type: ReviewItemId, assignId?: number) {
        for (let page of this.pages) {
            var item = _.find(page.items, (n) => n.type == type);
            if (item && item.notes) {
                for (let note of item.notes) {
                    if (note.assignId == assignId || (!assignId && !note.assignId)
                        && note.status !== NoteStatus.Dismissed) {
                        return { note: note, approveChange: item.approveChange };
                    }
                }
                return { note: undefined, approveChange: item.approveChange };
            }
        }
        return undefined;
    }

}

class ReviewServiceProvider implements angular.IServiceProvider {

    //$get = ['$rootScope', '$q', '$injector',
    //    function ($rootScope, $q, $injector) {
    //        return function (applicationId, userId, role, referenceId, itemId, showAsApplicant) {
    //            return new ReviewService($rootScope, $q, $injector.get('ReviewablePage'), applicationId, userId, role, referenceId, itemId, showAsApplicant)
    //        }

    //    }]

    $get = ['$rootScope', '$q', 'ReviewablePage',
        function ($rootScope, $q, ReviewablePage) {
            return function (applicationId, userId, role, referenceId, itemId, showAsApplicant) {
                return new ReviewService($rootScope, $q, ReviewablePage, applicationId, userId, role, referenceId, itemId, showAsApplicant)
            }

        }]
}

angular
    .module('app')
    .provider('ReviewService', ReviewServiceProvider)
