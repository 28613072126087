class timeCounterDirective implements ng.IDirective {
    restrict = 'AE'
    controller = timeCounterController
    controllerAs = '$ctrl'
    bindToController = true
    templateUrl = 'controls/timeCounter/timeCounter.html'
    scope = {
        timeCounter: '='
    }
}

class timeCounterController {

    timeCounter;

    seconds;
    minutes;
    hours;
    days

    static $inject = ['$scope', '$interval'];
    constructor(private $scope, private $interval) {

        let stopTime = this.$interval(() => {
            if (this.timeCounter) {
                let totalSeconds = Math.floor((new Date().getTime() - this.timeCounter.getTime()) / 1000);

                this.seconds = totalSeconds % 60;
                let totalMinutes = Math.floor(totalSeconds / 60);

                this.minutes = totalMinutes % 60;
                let totalHours = Math.floor(totalMinutes / 60);

                this.hours = totalHours % 24;
                this.days = Math.floor(totalHours / 24);
            }
        }, 1000)

        this.$scope.$on('$destroy', () => {
            this.$interval.cancel(stopTime);
        });
    }
}

angular
    .module('app')
    .directive('timeCounter', () => new timeCounterDirective());