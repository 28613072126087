
export class InterestedFamilyPageController {

    interestedFamilies: any[];
    isOnlineOrAwaiting: boolean;
    isToBeMatch: boolean;
    isRematch: boolean;
    isActiveApp: boolean;
    isPlacedOrActive: boolean;
    isPlaced: boolean;
    interestedFamiliesRequestStatus: string;
    pndMatch: boolean;

    static $inject = ['$scope', 'applicationId', 'PlacementMatchesDetails', 'InterestedHostFamiliesDetails', 'applicationObservable', '$state', '$stateParams', 'Auth']
    constructor($scope: angular.IScope, public applicationId, public PlacementMatchesDetails, public InterestedHostFamiliesDetails, applicationObservable, private $state, private $stateParams, private Auth) {
        
        applicationObservable.subscribe(applicationInstance => {
            this.isToBeMatch = (applicationInstance.status === 'TBM');
            this.isRematch = (applicationInstance.status === 'REM');
            this.isActiveApp = (applicationInstance.status === 'ACT');
            this.isPlaced = (applicationInstance.status === 'PLC');
            this.isOnlineOrAwaiting = (applicationInstance.status === 'OLA' || applicationInstance.status === 'AWA');
            this.isPlacedOrActive = (applicationInstance.status === 'PLC' || applicationInstance.status === 'ACT');

            if (this.isOnlineOrAwaiting || this.isToBeMatch) {
                PlacementMatchesDetails.getMatches({ applicationId: applicationId, status: 'PND' }).$promise.then((matches) => {
                    this.pndMatch = matches && matches.length > 0;
                });
            }

            if (this.isPlacedOrActive || this.isToBeMatch || this.isRematch) {
                this.interestedFamiliesRequestStatus = 'loading';
                this.InterestedHostFamiliesDetails.query({
                    applicationId: this.applicationId
                })
                    .$promise
                    .then((result) => {
                        this.interestedFamiliesRequestStatus = 'completed';
                        this.interestedFamilies = result;
                    }, () => {
                        this.interestedFamiliesRequestStatus = 'failed';
                    });
            }  
        })  
    }

    selectedProposal(hfData){

        this.$stateParams.proposalId = hfData.proposalId;
        this.$stateParams.photosUrl = hfData.photosURL;
        this.$stateParams.essayUrl = hfData.essayURL;
        this.$stateParams.email = hfData.email;

        var param = {
            proposalId: hfData.proposalId,
            photosUrl: hfData.photosURL,
            essayUrl: hfData.essayURL,
            email: hfData.email,
            requestId: hfData.requestId,
            hostFamilyId: hfData.hostFamilyId
        }
        if (this.Auth.user.isApplicant) {
            this.$state.go('app.placement.hostFamilyDocuments', param);
        } else {
            this.$state.go('app.applications.placement.hostFamilyDocuments', param);
        }

    }
}

angular
    .module('app')
    .controller('InterestedFamilyPageController', InterestedFamilyPageController)
