
class RecentlyViewedDirective implements ng.IDirective {
    restrict = 'AE'
    controller = RecentlyViewedController
    controllerAs = 'rvCtrl'
    bindToController = true
    templateUrl = 'areas/home/recentlyViewed/recentlyViewed.html'        
}

export enum UserRole {
    'Applicant' = 1,       
    'Interviewer',
    'BranchManager',
    'Agency',
    'OfficeUser'
}

class RecentlyViewedController {
        
    recentlyViewed: any[];       

    static $inject = ['$scope', 'RecentlyViewed', 'Auth', 'dialogs'];
    constructor(private $scope, private RecentlyViewed, private Auth, private dialogs) {
            
        this.recentlyViewed = this.RecentlyViewed.query();             
    }
}


angular
    .module('app')
    .directive('recentlyViewed', () => new RecentlyViewedDirective());
