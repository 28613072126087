import { DataAccessFactory } from './DataAccessFactory'
import { Auth, Resource } from './dataAccess'

export interface UserAction {
    description: string
    errorMessages?: Array<string>
    name: string
    performable: boolean
}

class UserFactory extends DataAccessFactory {

    constructor() {
        super()

        this.defaultActions = {
            save: { method: 'POST', params: { userId: '@userId' } },
        }
        this.server = 'users'
        this.initialize()
    }




    initialize() {
        let resources: Array<Resource> = [
            {
                name: 'Action',
                url: 'users/actions/:entryType/:entryId',
                actions:
                {
                    available: {
                        url: 'users/actions/:entryType/:entryId',
                        params: {
                            entryType: '@entryType',
                            entryId: '@entryId'
                        },
                        method: 'GET',
                        isArray: true
                    }
                }
            },
            {
                name: 'RecentlyViewed',
                url: 'users/:userId/recentlyViewed',
                actions: {
                    query: {
                        method: 'GET',
                        isArray: true
                    }
                }
            },
            {
                name: 'UserSettings',
                url: '',
                actions: {
                    get: {
                        url: 'users/:userId/userSettings',
                        method: 'GET',
                        params: { userId: '@userId' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertStringToCheckbox('countries')
                        )
                    },
                    save: {
                        url: 'users/:userId/userSettings',
                        method: 'POST',
                        params: { userId: '@userId' },
                        transformRequest: this.trans.convertCheckboxToString('countries')
                    }
                }
            },
            {
                name: 'UserLookup',
                url: '',
                actions: {
                    roleTypes: {
                        url: 'users/lookup/roleTypes',
                        method: 'GET',
                        params: {
                            minId: '@minId',
                            maxId: '@maxId'
                        },
                        isArray: true,
                        cache: true
                    },
                    businessAreas: {
                        url: 'users/lookup/businessAreas',
                        method: 'GET',
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItems((val, key) => val, (val, key) => key)
                        )
                    },
                    businessAreasWithDesc: {
                        url: 'users/lookup/businessAreasWithDesc',
                        method: 'GET',
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItemsWithTooltip('name', 'businessAreaId', 'tooltip')
                        )
                    }
                }
            },
            {
                name: 'OfficeUserAgency',
                url: 'users/:userId/officeUserAgencyId',
                actions: {
                    get: {
                        method: 'GET',
                        params: { userId: '@userId' }
                    }
                }
            },
            {
                name: 'WorkQueueSettings',
                url: 'users/:userId/workQueueSettings/:workQueueId',
                params: {
                    userId: '@userId',
                    workQueueId: '@workQueueId'
                },
                actions: {
                    query: {
                        url: 'users/:userId/workQueueSettings',
                        method: 'GET',
                        params: { userId: '@userId' }
                    },
                    save: {
                        url: 'users/:userId/workQueueSettings',
                        method: 'POST',
                        params: { userId: '@userId' }
                    },
                    remove: {
                        url: 'users/:userId/workQueueSettings/:workQueueId',
                        method: 'DELETE',
                        params: {
                            workQueueId: '@workQueueId',
                            userId: '@userId'
                        }
                    },
                    setAsDefault: {
                        method: 'POST',
                        url: 'users/:userId//workQueueSettings/:workQueueId/default',
                        params: {
                            workQueueId: '@workQueueId',
                            userId: '@userId'
                        }
                    }
                }
            },
            {
                name: 'WorkQueueColumnSelection',
                url: 'users/:userId/workQueueColumnSelection'
            },
            {
                name: 'SendBulkMessage',
                url: '',
                actions: {
                    send: {
                        url: 'messages/bulk',
                        method: 'POST',
                        params: {}
                    },
                    canSend: {
                        url: 'messages/validateBulkRecipients',
                        method: 'POST',
                        params: {},
                        isArray: true
                    }
                }
            },
            {
                name: 'MarketingPreferences',
                url: 'users/application/:applicationId/marketing',
                params: {
                    applicationId: '@applicationId'
                }
            },
            {
                name: 'MarketingPreferencesToken',
                url: 'users/marketing/:token',
                params: {
                    token: '@token'
                }
            },
            {
                name: 'ShownMessage',
                url: 'users/:userId/shownMessage/:messageId',
                params: {
                    userId: '@userId',
                    messageId: '@messageId'
                }
            },
            {
                name: 'CheckSigninFromAzure',
                auth: Auth.None,
                url: 'users/checkSigninFromAzure/:email',
                params: {
                    email: '@email'
                }
            },
            {
                name: 'LockedStatus',
                url: '',
                actions: {
                    isLockedOut: {
                        method: 'GET',
                        url: 'users/:userId/Locked',
                        params: { userId: '@userId' }
                    },
                    unlock: {
                        method: 'POST',
                        url: 'users/:userId/Unlock',
                        params: { userId: '@userId' }
                    }
                }
            },
            {
                name: 'OfficeUsers',
                url: 'users/officeUsers/:userId',
                params: { userId: '@userId' },
                actions: {
                    get: {
                        method: 'GET',
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertArrayToCheckbox('businessAreas')
                        )
                    },
                    save: {
                        method: 'POST',
                        transformRequest: this.trans.convertCheckboxToArray('businessAreas')
                    },
                    query: { method: 'GET', isArray: true }
                }
            },
            {
                name: 'User',
                url: 'users/:userId',
                params: { userId: '@userId' },
                actions: {
                    locked: {
                        method: 'GET',
                        url: 'users/:userId/locked'
                    },
                    unlock: {
                        method: 'POST',
                        url: 'users/:userId/unlock'
                    }
                }
            },
            {
                name: 'UserLastLogin',
                url: 'users/:userId/lastlogin',
                params: { userId: '@userId' },
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('lastLogin')
                        )
                    }
                }
            },
            {
                name: 'Agreement',
                url: 'users/agreement/:id',
                params: { id: '@id' }
            },
            {
                name: 'UserAgreement',
                url: 'users/:userId/agreements/:id',
                params: { id: '@id' },
                actions: {
                    query: { method: 'GET', isArray: true }
                }
            },
            {
                name: 'MaintainAgreements',
                url: '',
                actions: {
                    query: {
                        url: 'users/agreement',
                        isArray: false,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('agreements.validFrom', 'agreements.validTo')
                        )
                    },
                    get: {
                        url: 'users/agreement/:id',
                        params: {
                            id: '@agreementId'
                        },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('validFrom', 'validTo')
                        )
                    },
                    current: {
                        url: 'users/agreement/current',
                        method: 'GET',
                    },
                    saveAgreement: {
                        url: 'users/agreement/:id',
                        method: 'POST'
                    },
                    deleteAgreement: {
                        url: 'users/agreement/:id',
                        method: 'DELETE',
                        params: {
                            id: '@id'
                        },
                    }
                }
            }
        ]

        this.process(resources)
    }
}

new UserFactory()
