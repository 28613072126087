import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'

class UploadFactory extends DataAccessFactory {

    constructor() {
        super()
        this.server = 'upload'

        this.initialize()
    }

    initialize() {
        let resources: Array<Resource> = [
            {
                name: 'DocumentUpload',
                url: 'uploads/:documentId',
                actions: {
                    documentDefinition: { method: 'GET', url: 'uploads/documentDefinition/:documentType' },

                    deletecontactportrait: { method: 'DELETE', url: 'uploads/:contactdocumentId/deletecontactportrait' },

                    prepareUpload: { method: 'POST', url: 'uploads/document/prepareUpload' },
                    uploadSuccess: { method: 'POST', url: 'uploads/document/uploadSuccess' },
                    uploadFailed: { method: 'POST', url: 'uploads/document/uploadFailed' }
                }
            },
            {
                name: 'ApplicationDocument',
                url: 'uploads/applicationDocument/:documentId',
                actions: {
                    prepareUpload: { method: 'POST', url: 'uploads/applicationDocument/prepareUpload' },
                    uploadSuccess: { method: 'POST', url: 'uploads/applicationDocument/uploadSuccess' },
                    uploadFailed: { method: 'POST', url: 'uploads/applicationDocument/uploadFailed' },

                    uploadDescription: { method: 'GET', url: 'uploads/applicationDocument/:documentId/upload' },
                    addPage: { method: 'POST', url: 'uploads/applicationDocument/:documentId/applicationDocumentPage' },


                    update: { method: 'POST', url: 'uploads/applicationDocument/:documentId/updateComment' },
                    changeType: { method: 'POST', url: 'uploads/applicationDocument/:documentId/changeType' },
                    approve: { method: 'POST', url: 'uploads/applicationDocument/:documentId/approveDocument' },
                    unapprove: { method: 'POST', url: 'uploads/applicationDocument/:documentId/unapproveDocument' },
                    remove: { method: 'DELETE', url: 'uploads/applicationDocument/:documentId' },
                    query: { method: 'GET', url: 'uploads/applicationDocument/application/:applicationId/categories/:category'},
                    queryAll: { method: 'GET', url: 'uploads/applicationDocument/application/:applicationId' },
                    fetchUploadedDocumentsForReference: { method: 'GET', url: 'uploads/applicationReferenceDocument/application/:applicationId/category/:categoryId/reference/:referenceId' , isArray: true},
                    fetchIndividualDocument: { method: 'GET', url: 'uploads/applicationUniqueDocuments/application/:applicationId/category/:categoryId/extraReference/:extraReference', isArray: true }
                }
            },
            {
                name: 'ApplicationDocumentPage',
                url: 'uploads/applicationDocumentPage/:pageId',
                actions: {
                    remove: { method: 'DELETE', url: 'uploads/applicationDocumentPage/:pageId' }
                }
            },
            {
                name: 'ContactDocument',
                url: 'uploads/contactDocument/:contactdocumentId',
                actions: {
                    prepareUpload: { method: 'POST', url: 'uploads/contactDocument/prepareUpload' },
                    uploadSuccess: { method: 'POST', url: 'uploads/contactDocument/uploadSuccess' },
                    uploadFailed: { method: 'POST', url: 'uploads/contactDocument/uploadFailed' },

                    query: { method: 'GET', url: 'uploads/contactDocument/contact/:contactId/documentType/:documentType', isArray: true },
                    remove: { method: 'DELETE', url: 'uploads/contactDocument/:contactdocumentId' }
                }
            },
            {
                name: 'ContractDocument',
                url: '',
                actions: {
                    prepareUpload: { method: 'POST', url: 'uploads/contractDocument/prepareUpload' },
                    uploadSuccess: { method: 'POST', url: 'uploads/contractDocument/uploadSuccess' },
                    uploadFailed: { method: 'POST', url: 'uploads/contractDocument/uploadFailed' },

                    uploadDescription: { method: 'GET', url: 'uploads/contractDocument/:documentId/upload' },
                    addPage: { method: 'POST', url: 'uploads/contractDocument/:documentId/contractDocumentPage' },

                    query: { method: 'POST', url: 'uploads/contractDocuments', isArray: true },
                    remove: { method: 'DELETE', url: 'uploads/contractDocument/:documentId' },
                    removePage: { method: 'DELETE', url: 'uploads/contractDocumentPage/:documentFileId' },
                    hasDocumentExists: { method: 'POST', url: 'uploads/contractDocument/hasDocumentExists' },
                }
            },
            {
                name: 'ApplicationUploads',
                url: 'uploads/:applicationId/applicationUploads',
                actions: {
                    query: {
                        method: 'GET',
                        params: { applicationId: '@applicationId' },
                        isArray: true
                    },
                    getUpload: {
                        method: 'GET',
                        params: { applicationId: '@applicationId', documentId: '@applicationDocumentId'},
                        url: 'uploads/:applicationId/applicationUploads/:applicationDocumentId',
                        isArray: false
                    }
                }
            }
        ]

        this.process(resources)
    }
}

new UploadFactory()
