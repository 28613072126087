
export class HostFamiliesController {

    menu

    static $inject = ['$injector', '$scope', 'Menu'];
    constructor(private $injector, private $scope, Menu) {
        this.menu = Menu('hostFamilies')

        this.resolve();
    }

    resolve() {
        for (let item of this.menu.items) {
            this.resolveValues(item);
            if (item.items) {
                for (let subitem of item.items) {
                    this.resolveValues(subitem);
                }
            }

        }
    }

    resolveValues(item) {
        this.getValue('name', item);
        this.getValue('param', item);
        this.getValue('badge', item, 0);
        this.getValue('visibility', item, true);
    }


    getValue(prop, item, def?) {
        let dest = `_${prop}`;
        let value = item[prop];

        if (angular.isFunction(value) || angular.isArray(value)) {
            let result = this.$injector.invoke(value, item, { $scope: this.$scope });
            if (result && (typeof result.then === 'function')) {
                result.then((v) => item[dest] = v);
            } else {
                item[dest] = result;
            }
        } else if (value === undefined && def !== undefined) {
            item[dest] = def;
        } else {
            item[dest] = value;
        }
    }
}

angular
    .module('app')
    .controller('HostFamiliesController', HostFamiliesController);