import { ReferenceModule } from '../ReferenceModule';
import { ReferenceType } from '../../../models/reference/ReferenceType';

    class ReferenceRefereeController {

        saved: boolean;
        template: string;
        reference: any;
        childcare: any
        message: string;

        form;

        languages;

        static $inject = ['$scope', '$q', '$state', 'dialogs', 'Translation', 'Reference', 'referenceResponse', 'token'];
        constructor(private $scope, private $q, private $state, private dialogs, private Translation, private Reference, referenceResponse, private token) {

            if (referenceResponse.responseCode == 1) {
                this.reference = referenceResponse.reference;
                this.childcare = referenceResponse.childcare;
            } else {
                this.message = referenceResponse.message || '<p>This link has now expired. Please contact <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a> for a new reference form.</p>';
            }

            this.reference.language = Translation.lang();
            this.languages = Translation.availableLang();

            $scope.$watch(() => this.reference.language, (newValue, oldValue) => {
                if (newValue !== oldValue) {

                    this.Translation.changeLang(newValue || 'en');
                }
            });
        }

        private referenceType(): ReferenceType {
            if (!this.reference)
                return ReferenceType.Unknown;

            return <ReferenceType>this.reference.referenceType;
        }

        headerText() : string { //IDEA: if this is used anywhere else move it to a model first!
            switch (this.referenceType()) {
                case ReferenceType.Character:
                    return 'Character Reference'
                case ReferenceType.Educare:
                    return 'Educare Reference'
                case ReferenceType.Childcare:
                    return 'Childcare Reference'
                case ReferenceType.PreviousHostFamily:
                    return 'Previous Host Family Reference'
                case ReferenceType.FamilyMember:
                    //as per discussion we will keep display name as childcare
                    return 'Childcare Reference'
                default: 
                    return '';
            }
        }

        isCharacter = () => this.referenceType() === ReferenceType.Character;
        isEducare = () => this.referenceType() === ReferenceType.Educare;
        isChildcare = () => this.referenceType() === ReferenceType.Childcare;
        isFamily = () => this.referenceType() === ReferenceType.PreviousHostFamily;
        isFamilyMember = () => this.referenceType() === ReferenceType.FamilyMember;

        save() {

            this.$scope.$broadcast('submitted', true);

            let isValid = this.reference.$validation()
            if (isValid) {

                this.reference.agreedOn = new Date();

                this.Reference.saveForReferee({ token: this.token }, this.reference).$promise
                    .then(() => {
                        this.form.$setPristine();
                        this.$state.go('^.refereeComplete');
                    }, () => {
                        this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>');
                    });
            }
        }
    }

    ReferenceModule
        .instance
        .controller('ReferenceRefereeController', ReferenceRefereeController);